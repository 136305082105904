import React, { useState, useEffect, useRef } from "react";
import { IconButton, Tooltip, Link, Flex, Button } from "@chakra-ui/react";
import "./AffiliateLinks.css";
import { FaYelp, FaFoursquare, FaTripadvisor } from "react-icons/fa";
import { BsRecordCircleFill } from "react-icons/bs"; // Import the icon from react-icons

const AffiliateLinks = ({ bar, searchType }) => {
  const [isShareModalOpen, setShareModalOpen] = useState(false); // New state variable to handle share modal visibility
  const [shareLink, setShareLink] = useState(""); // New state variable to hold the share link

  const extractCityAndZip = (vicinity) => {
    // Assume the address format is "Street, City, State Zip, Country"
    const match = vicinity.match(
      /(.*),\s*([^,]+),\s*NY\s*(\d{5}),\s*United States/i
    );
    if (match) {
      const city = match[2].trim();
      const zip = match[3].trim();
      return { city, zip };
    }
    return { city: "New York", zip: "" };
  };

  const constructYelpUrl = (barName, vicinity) => {
    let { city, zip } = extractCityAndZip(vicinity);
    let formattedName = barName.replace(/\s+/g, "+");
    let formattedCity = city.replace(/\s+/g, "+");
    return `https://www.yelp.com/search?find_desc=${formattedName}&find_loc=${formattedCity}%2C+NY+${zip}`;
  };

  const constructTripAdvisorUrl = (barName, vicinity) => {
    let { city, zip } = extractCityAndZip(vicinity);
    let formattedName = barName.replace(/\s+/g, "+");
    let formattedCity = city.replace(/\s+/g, "+");
    return `https://www.tripadvisor.com/Search?q=${formattedName}+${formattedCity}%2C+NY+${zip}`;
  };

  const constructFoursquareUrl = (barName, vicinity) => {
    let { city, zip } = extractCityAndZip(vicinity);
    let formattedName = barName.replace(/\s+/g, "%20");
    let formattedCity = city.replace(/\s+/g, "%20");
    return `https://foursquare.com/explore?mode=url&near=${formattedCity}%2C+NY+${zip}%2C+United+States&nearGeoId=72057594043056517&q=${formattedName}`;
  };

  const constructOpenTableUrl = (barName, vicinity) => {
    let { city, zip } = extractCityAndZip(vicinity);
    let formattedName = encodeURIComponent(barName);
    let formattedCity = encodeURIComponent(city);
    return `https://www.opentable.com/s?dateTime=2023-09-26T19%3A00%3A00&covers=2&term=${formattedName}&near=${formattedCity}%2C+NY+${zip}&shouldUseLatLongSearch=false`;
  };

  return (
    <Flex className="aosisu" w="100%" justifyContent={"space-between"}>
      <Tooltip
        borderRadius={"8px"}
        label="View on Yelp"
        aria-label={`View ${bar.name} on Yelp`}
      >
        <Link
          href={constructYelpUrl(bar.name, bar.vicinity)}
          isExternal
          aria-label={`Go to Yelp for more about ${bar.name}`} // Descriptive label for the link
        >
          <IconButton
            size={"md"}
            onClick={(event) => event.stopPropagation()}
            isExternal
            display={"flex"}
            aria-label="Yelp"
            icon={<FaYelp color="white" />}
            variant="unstyled"
            background="rgba(255,0,0,0.6)" // Red background with some transparency
            borderRadius="50%" // To make it circular
            _hover={{
              background: "rgba(255,0,0,0.9)", // Darker Red when hovered
              transform: "scale(1.1)", // Scale icon size when hovered
              boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
            }}
          />
        </Link>
      </Tooltip>

      <Tooltip
        label="View on TripAdvisor"
        borderRadius={"8px"}
        aria-label={`View ${bar.name} on TripAdvisor`}
      >
        <Link
          href={constructTripAdvisorUrl(bar.name, bar.vicinity)}
          isExternal
          aria-label={`Go to TripAdvisor for more about ${bar.name}`} // Descriptive label for the link
        >
          <IconButton
            onClick={(event) => event.stopPropagation()}
            display={"flex"}
            aria-label="Tripadvisor"
            icon={<FaTripadvisor color="white" />}
            variant="unstyled"
            background="rgba(107,142,35,0.6)" // Olive Green background with some transparency
            borderRadius="50%" // To make it circular
            _hover={{
              background: "rgba(107,142,35,0.9)", // Darker Olive Green when hovered
              transform: "scale(1.1)", // Scale icon size when hovered
              boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
            }}
          />
        </Link>
      </Tooltip>

      <Tooltip
        borderRadius={"8px"}
        label="View on Foursquare"
        aria-label={`View ${bar.name} on Foursquare`}
      >
        <Link
          href={constructFoursquareUrl(bar.name, bar.vicinity)}
          isExternal
          aria-label={`Go to Foursquare for more about ${bar.name}`} // Descriptive label for the link
        >
          <IconButton
            onClick={(event) => event.stopPropagation()}
            display={"flex"}
            aria-label="Foursquare"
            icon={<FaFoursquare color="white" />}
            variant="unstyled"
            background="rgba(0,128,0,0.6)" // Green background with some transparency
            borderRadius="50%" // To make it circular
            _hover={{
              background: "rgba(0,128,0,0.9)", // Darker Green when hovered
              transform: "scale(1.1)", // Scale icon size when hovered
              boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
            }}
          />
        </Link>
      </Tooltip>

      <Tooltip
        borderRadius={"8px"}
        label="View on OpenTable"
        aria-label={`View ${bar.name} on OpenTable`}
      >
        <Link
          href={constructOpenTableUrl(bar.name, bar.vicinity)}
          isExternal
          aria-label={`Go to OpenTable for more about ${bar.name}`} // Descriptive label for the link
        >
          <IconButton
            onClick={(event) => event.stopPropagation()}
            isExternal
            display={"flex"}
            aria-label="OpenTable"
            icon={<BsRecordCircleFill color="white" />}
            variant="unstyled"
            background="rgba(255,0,0,0.6)" // Red background with some transparency
            borderRadius="50%" // To make it circular
            _hover={{
              background: "rgba(255,0,0,0.9)", // Darker Red when hovered
              transform: "scale(1.1)", // Scale icon size when hovered
              boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
            }}
          />
        </Link>
      </Tooltip>
    </Flex>
  );
};

export default AffiliateLinks;
