import React, { useEffect, useState } from "react";
import "./MainHero.css";
import {
  Box,
  Button,
  Flex,
  Icon,
  IconButton,
  Image,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Tooltip,
} from "@chakra-ui/react";
import SearchNYC from "../Search/V2/NYC/SearchNYC";
import MenuBarIcon from "../../Svg/dodtsyu.svg"; // Adjust the path to where your SVG is located
import { BsPlus, BsPlusSquareDotted, BsPlusSquareFill } from "react-icons/bs";
import { useCookies } from "react-cookie";
import BarUpload from "../Upload/BarUpload";
import Login from "../Auth/Login";

const MainHero = ({ location }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const userName = cookies.Name;
  const manhattanPath = location.pathname === "/bars-in/Manhattan";
  const brooklynPath = location.pathname === "/bars-in/Brooklyn";
  const queensPath = location.pathname === "/bars-in/Queens";
  const bronxPath = location.pathname === "/bars-in/Bronx";
  const statenIslandPath = location.pathname === "/bars-in/Staten-Island";
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box className="mifgh">
      <Box
        mb={1}
        bgGradient="linear(to-r, teal.400,green.400)"
        className="wrageust"
      >
        <Flex gap={"10px"} className="hopssos">
          {isMobileView ? (
            <Image src={MenuBarIcon} width="40px" height="40px" />
          ) : (
            <Image src={MenuBarIcon} width="70px" height="70px" />
          )}

          {brooklynPath ? (
            <Text className="allletsu">Brooklyn Bars</Text>
          ) : queensPath ? (
            <Text className="allletsu">Queens Bars</Text>
          ) : bronxPath ? (
            <Text className="allletsu">Bronx Bars</Text>
          ) : statenIslandPath ? (
            <Text className="allletsu">Staten Island Bars</Text>
          ) : manhattanPath ? (
            <Text className="allletsu">Manhattan Bars</Text>
          ) : (
            <Text className="allletsu">NYC Bars</Text>
          )}
        </Flex>
        <Box mb={2} className="wildsui">
          {isMobileView ? (
            <Text className="Explore-New" textAlign={"center"}>
              Discover NYC bars, including Brooklyn Bars, Queens Bars, bars in
              the Bronx , and bars in Staten Island.
            </Text>
          ) : (
            <Text className="Explore-New" textAlign={"center"}>
              Explore New York City's bar scene. Discover NYC bars, including
              Brooklyn Bars, Queens Bars, bars in the Bronx, and bars in Staten
              Island.
            </Text>
          )}
        </Box>
        <Box mt={3} className="mainherosearcho">
          <SearchNYC />
          <Box>
            {isMobileView ? (
              <>
                <IconButton
                  icon={<BsPlus fontSize={"25px"} />}
                  colorScheme="orange"
                  onClick={onOpen} // Add this line
                />
              </>
            ) : (
              <>
                <Tooltip borderRadius={"8px"} label="Add Bar" placement={"top"}>
                  <Button
                    onClick={onOpen} // Add this line
                    bg={"ButtonFace"}
                    variant={"outline"}
                    colorScheme="orange"
                    border={"none"}
                    _hover={{
                      transform: "scale(1.05)",
                      boxShadow: "lg",
                    }}
                    _active={{
                      transform: "scale(0.95)",
                    }}
                  >
                    <Flex alignItems={"center"} gap={"6px"}>
                      <Icon as={BsPlusSquareDotted} />
                      <Text fontSize={"15px"}>Add Bar</Text>
                    </Flex>
                  </Button>
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {AuthToken ? (
              <>
                <Text>Add a new Bar below</Text>
              </>
            ) : (
              <>
                <Text>Please Login Below!</Text>
              </>
            )}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {AuthToken ? (
              <>
                <BarUpload isMobileView={isMobileView} onCloseModal={onClose} />
              </>
            ) : (
              <>
                <Login onClose={onClose} />
              </>
            )}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MainHero;
