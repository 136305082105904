import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  List,
  ListItem,
  Box,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import "./BulkModal.css";

const BulkModal = () => {
  const [isAddBarModalOpen, setAddBarModalOpen] = useState(false);
  const [bars, setBars] = useState([]);
  const toast = useToast();

  return (
    <>
      <Box className="bretwy"></Box>
    </>
  );
};

export default BulkModal;
