import {
  Flex,
  Heading,
  useDisclosure,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  Icon,
  Button,
  Tooltip,
  Link,
  Modal,
  ModalOverlay,
  useToast,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import "./MobileHeader.css";
import GroupIcon from "../../Svg/Group.svg"; // Adjust the path to where your SVG is located
import { useCookies } from "react-cookie";
import {
  TbCircleLetterB,
  TbCircleLetterM,
  TbCircleLetterQ,
  TbCircleLetterS,
  TbLogout,
  TbUserCircle,
} from "react-icons/tb";
import { BsArrowUpRightCircle, BsDoorOpen } from "react-icons/bs";
import CreateAccount from "../Auth/CreateAccount";
import Login from "../Auth/Login";
import { useResetSearch } from "../../Context/ResetSearchContext";
import { useSearchedBarAllNyc } from "../../Context/SearchedBarAllNycContext";

const MobileHeader = ({ isMobileView }) => {
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);

  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);
  const toast = useToast();

  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const userName = cookies.Name;
  const userEmail = cookies.Email;

  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();

  const location = useLocation();
  const [selectedNav, setSelectedNav] = useState("/");

  useEffect(() => {
    setSelectedNav(location.pathname);
  }, [location]);

  const handleLogout = () => {
    removeCookie("AuthToken");
    removeCookie("UserId");
    removeCookie("Name");
    removeCookie("Email");
    setIsLogoutConfirmOpen(false); // Close the logout confirmation modal
    toast({
      title: "Logged Out",
      description: "You are successfully signed out.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/");
  };

  const toProfile = () => {
    navigate("/user/profile");
    window.scrollTo(0, 0);
    setSearchedSelectedBarAllNyc(null);
    setSearchValueContext("");
  };

  const { setSearchedSelectedBarAllNyc } = useSearchedBarAllNyc();

  const { searchValueContext, setSearchValueContext, resetSearchContext } =
    useResetSearch();

  const homeAndClose = () => {
    navigate("/");
    setSearchedSelectedBarAllNyc(null);
    setSearchValueContext(""); // Reset the context's search value to an empty string
  };

  const handleNavigation = (path) => {
    setSearchedSelectedBarAllNyc(null);
    setSearchValueContext(""); // Reset the context's search value to an empty string
    navigate(path);
  };

  const openLogoutConfirm = () => setIsLogoutConfirmOpen(true);
  const closeLogoutConfirm = () => setIsLogoutConfirmOpen(false);

  return (
    <>
      <Flex
        as="header"
        position="fixed"
        top="0"
        left="0"
        right="0"
        h={"8%"}
        bg="rgba(46, 83, 129, 1)"
        color="rgba(15, 63, 98, 1)"
        alignItems="center"
        px={"15px"}
        zIndex="9000"
        justifyContent={"space-between"}
        boxShadow={"md"}
      >
        <Flex alignItems={"center"} gap={"15px"}>
          <Box>
            <Menu className="picksl">
              <MenuButton
                size={"sm"}
                as={IconButton}
                aria-label="Options" // This is already a good ARIA attribute you have.
                aria-haspopup="true" // Indicates that this button has a popup menu.
                icon={<HamburgerIcon />}
              />
              <MenuList className="ileria">
                <ul>
                  <MenuItem
                    className={`partharn ${
                      selectedNav === "/bars-in/Manhattan"
                        ? "activeLinkmobile"
                        : ""
                    }`}
                    onClick={() => handleNavigation("/bars-in/Manhattan")}
                  >
                    <Icon
                      color={"red"}
                      mr={2}
                      as={TbCircleLetterM}
                      className="flandertsy"
                    />
                    Manhattan Bars
                  </MenuItem>
                  <MenuItem
                    className={`partharn ${
                      selectedNav === "/bars-in/Brooklyn"
                        ? "activeLinkmobile"
                        : ""
                    }`}
                    onClick={() => handleNavigation("/bars-in/Brooklyn")}
                  >
                    <Icon
                      color={"green"}
                      mr={2}
                      as={TbCircleLetterB}
                      className="flandertsy"
                    />
                    Brooklyn Bars
                  </MenuItem>
                  <MenuItem
                    className={`partharn ${
                      selectedNav === "/bars-in/Queens"
                        ? "activeLinkmobile"
                        : ""
                    }`}
                    onClick={() => handleNavigation("/bars-in/Queens")}
                  >
                    <Icon
                      color={"orange"}
                      mr={2}
                      as={TbCircleLetterQ}
                      className="flandertsy"
                    />{" "}
                    Queens Bars
                  </MenuItem>
                  <MenuItem
                    className={`partharn ${
                      selectedNav === "/bars-in/Bronx" ? "activeLinkmobile" : ""
                    }`}
                    onClick={() => handleNavigation("/bars-in/Bronx")}
                  >
                    <Icon
                      color={"blue"}
                      mr={2}
                      as={TbCircleLetterB}
                      className="flandertsy"
                    />{" "}
                    Bronx Bars
                  </MenuItem>
                  <MenuItem
                    className={`partharn ${
                      selectedNav === "/bars-in/Staten-Island"
                        ? "activeLinkmobile"
                        : ""
                    }`}
                    onClick={() => handleNavigation("/bars-in/Staten-Island")}
                  >
                    <Icon
                      color={"purple"}
                      mr={2}
                      as={TbCircleLetterS}
                      className="flandertsy"
                    />{" "}
                    Staten Island Bars
                  </MenuItem>
                </ul>
              </MenuList>
            </Menu>
          </Box>
          <Box>
            {" "}
            <Heading
              _hover={{ color: "teal.300", cursor: "pointer" }}
              display={"flex"}
              alignItems={"center"}
              size="md"
              color={"white"}
              onClick={homeAndClose}
            >
              <Image
                src={GroupIcon}
                width="15px"
                height="28px"
                marginRight="3px"
              />{" "}
              Nyc Bars
            </Heading>
          </Box>
        </Flex>
        <Box>
          {AuthToken ? (
            <>
              <Menu>
                <Tooltip
                  borderRadius={"8px"}
                  label="Your Account"
                  placement="bottom"
                >
                  <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                  >
                    <Flex p={1} gap={"6px"} alignItems={"center"}>
                      <Avatar size={"xs"} name={userName} />
                      <Text className="aoetsy">{userEmail}</Text>
                    </Flex>
                  </MenuButton>
                </Tooltip>

                <MenuList>
                  <MenuItem className="mentions" onClick={toProfile}>
                    <Icon mr={2} className="auiosyuyts" as={TbUserCircle} />
                    Profile
                  </MenuItem>
                  <MenuItem
                    className="logoutmentions"
                    colorScheme="red"
                    onClick={openLogoutConfirm}
                  >
                    <Icon mr={2} className="auiosyuyts" as={TbLogout} />
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            <>
              <Box>
                <Tooltip
                  borderRadius={"8px"}
                  label="Create an account"
                  placement="bottom"
                >
                  <Button
                    mr={2}
                    colorScheme="cyan"
                    size={"sm"}
                    onClick={onOpen}
                  >
                    Join!
                  </Button>
                </Tooltip>
                <Tooltip
                  borderRadius={"8px"}
                  label="Login to your account"
                  placement="bottom"
                >
                  <Button colorScheme="green" size={"sm"} onClick={onLoginOpen}>
                    <Flex gap={"4px"} alignItems={"center"}>
                      <Text>Login</Text>
                      <Icon as={BsArrowUpRightCircle} />
                    </Flex>
                  </Button>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="makemodal">
          <Box className="trebbbaj">
            <Flex gap={"10px"} alignItems={"center"}>
              <Text className="platertuio">Welcome!</Text>
              <Icon className="raaamsp" as={BsDoorOpen} />
            </Flex>
          </Box>
          <ModalCloseButton />
          <CreateAccount isMobileView={isMobileView} closeModal={onClose} />
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isLoginOpen} onClose={onLoginClose}>
        <ModalOverlay />
        <ModalContent className="makemodal">
          <ModalHeader>Login to Your Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Login closeModal={onLoginClose} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isLogoutConfirmOpen} onClose={closeLogoutConfirm}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex gap={"10px"} alignItems={"center"}>
              <Text>Confirm Logout</Text>
              <Icon as={TbLogout} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to sign out?</ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="gray"
              mr={3}
              onClick={closeLogoutConfirm}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="red" onClick={handleLogout}>
              Yes, Sign Out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default MobileHeader;
