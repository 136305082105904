import React, { createContext, useContext, useState } from "react";

const SearchedBarContext = createContext();

export const useSearchedBar = () => useContext(SearchedBarContext);

export const SearchedBarProvider = ({ children }) => {
  const [searchedSelectedBar, setSearchedSelectedBar] = useState(null);

  return (
    <SearchedBarContext.Provider
      value={{ searchedSelectedBar, setSearchedSelectedBar }}
    >
      {children}
    </SearchedBarContext.Provider>
  );
};
