import React, { createContext, useContext, useState } from "react";

const SingleBarItemContext = createContext();

export const useSingleBarItem = () => useContext(SingleBarItemContext);

export const SingleBarItemProvider = ({ children }) => {
  const [singleBarItem, setSingleBarItem] = useState(null);

  return (
    <SingleBarItemContext.Provider value={{ singleBarItem, setSingleBarItem }}>
      {children}
    </SingleBarItemContext.Provider>
  );
};
