import React, { useState, useEffect } from "react";
import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import Searching from "../../Components/Search/Searching";
import MainBarList from "../../Components/Lists/MainBarList";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./Manhattan.css";
import ManhattanBarList from "../../Components/Lists/ManhattanBarList/ManhattanBarList";
import { Helmet } from "react-helmet";
import { fetchAllBarsInManhattan } from "../../../src/api/GooglePlacesAPI";
import SearchManhattanBars from "../../Components/Search/V2/Manhattan/SearchManhattanBars";

const Manhattan = () => {
  const [resetFlag, setResetFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <>
      <Helmet>
        <title>Manhattan Bars | Bars in NYC </title>
        <meta
          name="description"
          content="Bars in Manhattan. Featuring bars in the Upper East Side, bars in Chelsea, bars in the East Village and more."
        />
      </Helmet>

      <Box className="home-contaner">
        <Box className="sim3q">
          <Box w={"100%"} className="barlist">
            <section>
              <ManhattanBarList
                searchQuery={searchQuery}
                resetFlag={resetFlag}
              />
            </section>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Manhattan;
