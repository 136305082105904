// AddNewBarContext.js
import React, { createContext, useContext, useState } from "react";

const AddNewBarContext = createContext();

export const useAddNewBar = () => useContext(AddNewBarContext);

export const AddNewBarProvider = ({ children }) => {
  const [bars, setBars] = useState([]);

  const addNewBar = (newBar) => {
    setBars((prevBars) => [...prevBars, newBar]);
  };

  const value = {
    bars,
    addNewBar,
  };

  return (
    <AddNewBarContext.Provider value={value}>
      {children}
    </AddNewBarContext.Provider>
  );
};
