import React, { createContext, useContext, useState } from "react";

const SearchedBarAllNycContext = createContext();

export const useSearchedBarAllNyc = () => useContext(SearchedBarAllNycContext);

export const SearchedBarAllNycProvider = ({ children }) => {
  const [searchedSelectedBarAllNyc, setSearchedSelectedBarAllNyc] =
    useState(null);

  return (
    <SearchedBarAllNycContext.Provider
      value={{ searchedSelectedBarAllNyc, setSearchedSelectedBarAllNyc }}
    >
      {children}
    </SearchedBarAllNycContext.Provider>
  );
};
