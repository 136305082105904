import React, { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  Button,
  Flex,
  Tooltip,
  Icon,
  Spinner,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import "./BarItem.css";
import { RiHeart2Line } from "react-icons/ri";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { useCookies } from "react-cookie";
import axios from "axios";
import { API_ENDPOINT } from "../../Config/config";

const BarItem = ({ bar, onDelete, likedBars }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const userName = cookies.Name;
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast(); // Initialize the toast
  const [likingBarId, setLikingBarId] = useState(null);
  const [unlikingBarId, setUnLikingBarId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isUnlikeModalOpen, setIsUnlikeModalOpen] = useState(false);
  const showUnlikeModal = () => setIsUnlikeModalOpen(true);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await axios.delete(`${API_ENDPOINT}/api/deleteBar/${bar._id}`);
      toast({
        title: "Bar deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose(); // Close the modal
      onDelete(); // Trigger refresh of the bar list in the parent component
    } catch (error) {
      toast({
        title: "Error deleting the bar.",
        description: "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsDeleting(false);
    }
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const showDeleteModal = () => setIsDeleteModalOpen(true);

  const handleLikeClick = async (barId) => {
    setLikingBarId(barId); // Start loading for this specific bar
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/user/${userId}/likeBar`,
        { barId, userId }
      );
      if (response.data.status === "success") {
        toast({
          title: "Bar liked successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error liking the bar.",
        description: "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLikingBarId(null); // End loading
    }
  };

  const handleUnlike = async () => {
    try {
      await axios.post(`${API_ENDPOINT}/api/user/${userId}/unlikeBar`, {
        barId: bar._id,
        userId: userId,
      });
      toast({
        title: "Bar Unliked",
        description: "This bar has been removed from your liked save list.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "An error occurred while unliking the bar.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      key={bar._id}
      p={5}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      position="relative"
      _hover={{ shadow: "lg" }}
    >
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Text className="lauuevs">{bar.name}</Text>

        {likedBars ? (
          <>
            <Tooltip
              borderRadius={"8px"}
              mb={1}
              label="Bar Saved"
              zIndex={"9999"}
              placement="top"
            >
              <Icon
                _hover={{ cursor: "pointer" }}
                as={FaHeart}
                fontSize={"19px"}
                color="orange"
                onClick={showUnlikeModal}
              />
            </Tooltip>
          </>
        ) : (
          <>
            <Tooltip
              borderRadius={"8px"}
              mb={1}
              label="Bar Saved"
              zIndex={"9999"}
              placement="top"
            >
              <Icon
                _hover={{ cursor: "pointer" }}
                as={FaHeart}
                fontSize={"19px"}
                color="black"
              />
            </Tooltip>
          </>
        )}
      </Flex>
      <Text mt={4}>{bar.formattedAddress}</Text>
      <Box className="nansk">
        <Box className="bardedimagecont">
          <Image
            className="tacktis"
            src={bar.imageUrl}
            alt={`Image of ${bar.barName}`}
            borderRadius="md"
          />
        </Box>
      </Box>
      <Box mt={4} w={"100%"}>
        <Button
          colorScheme="red"
          variant={"outline"}
          size={"sm"}
          w={"100%"}
          onClick={showDeleteModal}
        >
          Delete
        </Button>
      </Box>
      <Modal
        isOpen={isUnlikeModalOpen}
        onClose={() => setIsUnlikeModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Unlike</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to unlike and remove this bar from your saved
            liked files?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={() => {
                handleUnlike();
                setIsUnlikeModalOpen(false);
              }}
            >
              Unlike
            </Button>
            <Button variant="ghost" onClick={() => setIsUnlikeModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete Bar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete this bar from the database?
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="red"
              mr={3}
              onClick={handleDelete}
              isLoading={isLoading}
            >
              Delete
            </Button>
            <Button variant="ghost" onClick={() => setIsDeleteModalOpen(false)}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BarItem;
