import React, { useState, useRef } from "react";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Image,
  Select,
  Flex,
  useToast,
  Icon,
} from "@chakra-ui/react";
import "./BarUpload.css";
import axios from "axios";
import { useCookies } from "react-cookie";
import { TbCircleCheckFilled, TbRotate } from "react-icons/tb";
import { useBars } from "../../Context/BarContext";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { FaTimes, FaTimesCircle, FaChevronRight } from "react-icons/fa";
import { API_ENDPOINT } from "../../Config/config";
import { SearchIcon } from "@chakra-ui/icons"; // Assuming you're using Chakra UI icons
import SearchAndUpload from "./SearchAndUpload/SearchAndUpload";
import ManualAddBar from "./ManualUpload/ManualAddBar";

const BarUpload = ({ onCloseModal, isMobileView }) => {
  return (
    <Box className="BarUpload-container" boxShadow="md" borderRadius="lg">
      <>
        <Box>
          <SearchAndUpload
            onCloseModal={onCloseModal}
            isMobileView={isMobileView}
          />
        </Box>

        {/* <Box>
          <ManualAddBar isMobileView={isMobileView} />
        </Box> */}
      </>
    </Box>
  );
};

export default BarUpload;
