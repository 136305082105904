// BarContext.js
import axios from "axios";
import React, { createContext, useState, useContext } from "react";
import { useCookies } from "react-cookie";
import { API_ENDPOINT } from "../Config/config";

const BarContext = createContext();

export const useBars = () => useContext(BarContext);

export const BarProvider = ({ children }) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const [bars, setBars] = useState([]);
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const [isLoading, setIsLoading] = useState(false); // Added loading state

  const fetchBars = async () => {
    setIsLoading(true); // Set loading to true before fetching

    try {
      const response = await axios.get(
        `${API_ENDPOINT}/api/user/${userId}/bars`
      );
      setBars(response.data.data);
    } catch (error) {
      console.error("Error fetching bars:", error);
    } finally {
      setIsLoading(false); // Set loading to false after fetching
    }
  };

  return (
    <BarContext.Provider value={{ bars, setBars, fetchBars, isLoading }}>
      {children}
    </BarContext.Provider>
  );
};
