import React, { createContext, useContext, useState } from "react";
import axios from "axios";
import { API_ENDPOINT } from "../Config/config";

const IndividualBarByIdContext = createContext();

export function useIndividualBarById() {
  return useContext(IndividualBarByIdContext);
}

export const IndividualBarByIdProvider = ({ children }) => {
  const [barData, setBarData] = useState({});

  return (
    <IndividualBarByIdContext.Provider value={{ barData }}>
      {children}
    </IndividualBarByIdContext.Provider>
  );
};
