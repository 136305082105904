import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  IconButton,
  Flex,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { AiOutlineCopy } from "react-icons/ai";
import "./BrooklynHomeList.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsArrowUpRightCircle, BsShareFill } from "react-icons/bs";
import { TbCircleLetterB, TbHeartPlus } from "react-icons/tb";
import { useClipboard, useToast } from "@chakra-ui/react";
import GroupIcon from "../../../Svg/ewfewf.svg"; // Adjust the path to where your SVG is located
import axios from "axios";
import { useCookies } from "react-cookie";
import { AddIcon, StarIcon } from "@chakra-ui/icons";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import SocialShare from "../../Share/SocialShare";
import { useNavigate } from "react-router-dom";
import AffiliateLinks from "../../AffiliateLinks/AffiliateLinks";
import Login from "../../Auth/Login";
import { API_ENDPOINT } from "../../../Config/config";
import BarModal from "../../BarModel/BarModal";
import MainBarItem from "../../BarItem/v2/MainBarItem";

const BrooklynHomeList = ({ isMobileView }) => {
  const [bars, setBars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedBar, setSelectedBar] = useState(null); // State to store the selected bar
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const toast = useToast();
  const [isSaving, setIsSaving] = useState(false); // State to indicate saving process
  const [savingBarId, setSavingBarId] = useState(null); // State to track saving bar's ID
  const [isSaveAllModalOpen, setIsSaveAllModalOpen] = useState(false);
  const [totalBarsCount, setTotalBarsCount] = useState(0); // New state for total bars count
  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId;
  const [likingBarId, setLikingBarId] = useState(null);
  const navigate = useNavigate();

  const [showLoginModal, setShowLoginModal] = useState(false); // State for showing the login modal

  const handleLikeClickOrLogin = (event, barId) => {
    event.stopPropagation(); // Prevent further propagation of the click event

    if (AuthToken) {
      handleLikeClick(barId);
    } else {
      setShowLoginModal(true); // Open the login modal
    }
  };

  const buildBarUrl = (barName) => {
    return `/bars-in/Brooklyn/${encodeURIComponent(barName)}`;
  };

  const handleLikeClick = async (barId) => {
    setLikingBarId(barId); // Start loading for this specific bar
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/user/${userId}/likeBar`,
        { barId, userId }
      );
      if (response.data.status === "success") {
        toast({
          title: "Bar liked successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error liking the bar.",
        description: "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLikingBarId(null); // End loading
    }
  };

  const [isShareIconButtonModalOpen, setShareIconButtonModalOpen] =
    useState(false);

  const handleShareIconButtonClick = (event, bar) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setShareIconButtonModalOpen(true);
    setSelectedBar(bar); // Set the selected bar
  };

  const fetchBarsInBrooklyn = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/api/getBrooklynBarsLimited`
      );
      setBars(response.data.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching bars:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBarsInBrooklyn();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 10,
        },
      },
    ],
  };

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBar(null);
    window.history.pushState({}, "", "/bars-in/Brooklyn"); // Reset URL to the default
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const isHalfStar = rating - fullStars >= 0.5; // Determine if there's a half star
    const halfStars = isHalfStar ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {Array(fullStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`full-${i}`} as={StarIcon} color="yellow.400" />
          ))}
        {Array(halfStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`half-${i}`} as={StarIcon} color="yellow.200" />
          ))}
        {Array(emptyStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`empty-${i}`} as={StarIcon} color="gray.300" />
          ))}
      </>
    );
  };

  const handleBarClick = (bar) => {
    setSelectedBar(bar);
    setIsModalOpen(true);
    const barUrl = buildBarUrl(bar.name);
    window.history.pushState({ bar: bar.name }, "", barUrl);
  };

  const handleNavigate = () => {
    navigate("/bars-in/Brooklyn");
    window.scrollTo(0, 0);
  };

  const handleImageClick = (bar) => {
    setSelectedBar(bar); // Set the selected bar details
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handleCopy = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast({
          title: "Address copied",
          description: `${address} has been copied to your clipboard.`,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  return (
    <Box className="brooklynsk">
      <Box mb={2} className="nowuesy">
        <Flex alignItems={"center"} gap={"5px"}>
          <Icon
            fontSize={isMobileView ? "34px" : "30px"}
            color={"green"}
            as={TbCircleLetterB}
          />
          <Text className="nipsye">Brooklyn Bars</Text>
        </Flex>

        <Flex alignItems={"center"} gap={"5px"}>
          {isMobileView ? (
            <>
              <Button
                onClick={handleNavigate}
                bg={"white"}
                variant={"outline"}
                size={"sm"}
              >
                <Text className="bdays">
                  Bars In Brooklyn <Icon ml={2} as={BsArrowUpRightCircle} />{" "}
                </Text>
              </Button>
            </>
          ) : (
            <>
              {AuthToken && (
                <IconButton
                  icon={<AddIcon />}
                  size={"sm"}
                  onClick={() => setIsSaveAllModalOpen(true)}
                />
              )}
              <Button
                onClick={handleNavigate}
                bg={"white"}
                variant={"outline"}
                size={"sm"}
                _hover={{
                  transform: "scale(1.1)", // Scale icon size when hovered
                  boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
                  backgroundColor: "rgba(60, 242, 78, 0.1)",
                }}
              >
                Bars In Brooklyn <Icon ml={2} as={BsArrowUpRightCircle} />{" "}
              </Button>
            </>
          )}
        </Flex>
      </Box>

      <Slider className="evenslider" {...sliderSettings}>
        {bars.map((bar, index) => (
          <>
            <Box key={index}>
              <MainBarItem bar={bar} isMobileView={isMobileView} />
            </Box>
          </>
        ))}
      </Slider>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{selectedBar?.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={selectedBar?.imageUrl}
              alt={`Image of ${selectedBar?.name}`}
            />
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {selectedBar && (
        <>
          <BarModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            selectedBar={selectedBar}
            isMobileView={isMobileView}
            GOOGLE_API_KEY={GOOGLE_API_KEY}
            renderStars={renderStars}
          />
        </>
      )}
      <Modal
        isOpen={isShareIconButtonModalOpen}
        onClose={() => setShareIconButtonModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share {selectedBar?.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedBar && <SocialShare barName={selectedBar.name} />}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please Login Below!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Login onClose={() => setShowLoginModal(false)} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default BrooklynHomeList;
