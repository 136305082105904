import React, { useEffect, useState } from "react";
import "./Profile.css";
import {
  Box,
  Text,
  VStack,
  Avatar,
  Badge,
  Divider,
  Button,
  Icon,
  Flex,
  Image,
  Menu,
  MenuButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { AiOutlinePlusSquare } from "react-icons/ai";
import savediconbar from "../../Svg/savedbar.svg"; // Adjust the path to where your SVG is located
import SavedBarList from "../../Components/Lists/Profile/SavedBarList";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import AddedBars from "../../Components/Lists/Profile/AddedBars";
import { BiPlus } from "react-icons/bi";
import BarUpload from "../../Components/Upload/BarUpload";
import { useBars } from "../../Context/BarContext";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import { useToast } from "@chakra-ui/react";
import BarPostIcon from "../../Svg/barpostsvg.svg"; // Adjust the path to where your SVG is located
import { API_ENDPOINT } from "../../Config/config";

const Profile = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const userName = cookies.Name;
  const [userData, setUserData] = useState(null);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const toast = useToast(); // Initialize useToast

  const [isAddBarModalOpen, setIsAddBarModalOpen] = useState(false);
  const openAddBarModal = () => setIsAddBarModalOpen(true);
  const closeAddBarModal = () => setIsAddBarModalOpen(false);

  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const navigate = useNavigate(); // Hook for navigation

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}/api/user/${userId}`);
        if (response.ok) {
          const data = await response.json();
          setUserData(data.data);
        } else {
          // Handle errors
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId]);

  const [selectedFilter, setSelectedFilter] = useState("Newest");
  const { fetchBars } = useBars(); // Use the useBars hook

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);
  const [activeList, setActiveList] = useState("addedBars"); // 'savedBars' or 'addedBars'

  const deleteAccount = async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}/api/user/${userId}`, {
        method: "DELETE",
      });

      if (response.ok) {
        // Clear cookies
        removeCookie("AuthToken", { path: "/" });
        removeCookie("UserId", { path: "/" });
        removeCookie("Name", { path: "/" });
        removeCookie("Email", { path: "/" });

        toast({
          title: "Account deleted.",
          description: "Your account has been successfully deleted.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setUserData(null);

        // Redirect to home page
        navigate("/");
      } else {
        console.error("Failed to delete account");
        toast({
          title: "Deletion Failed",
          description: "Failed to delete your account. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  return (
    <Box className="Profileview">
      {isMobileView && (
        <Box className="sidertwo">
          <Box className="baseseen">
            <Box bg="white" boxShadow="base" p={4} rounded="md">
              <VStack spacing={3} align="stretch">
                <Avatar size="xl" name={userData?.name} bg="teal.500" />
                <Text fontSize="2xl" fontWeight="bold">
                  {userData?.name}
                </Text>
                <Text fontSize="md">Email: {userData?.email}</Text>
                <Text fontSize="md">User ID: {userId}</Text>
                <Badge variant="solid" colorScheme="green">
                  Active Member
                </Badge>
              </VStack>
            </Box>
          </Box>
          <Divider orientation="horizontal" />
          <Box>
            <Box bg="white" boxShadow="base" p={4} rounded="md">
              <Text fontSize="lg" fontWeight="semibold">
                Account Details
              </Text>
              <Text fontSize="md">
                Registration Date:{" "}
                {new Date(userData?.registrationDate).toLocaleDateString()}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
      <Box className="siderone">
        <Box className="ttaps">
          <Button
            onClick={() => setActiveList("addedBars")} // Set active list to addedBars
            className={
              activeList === "addedBars" ? "button-active" : "button-normal"
            }
            w={isMobileView ? "100%" : ""}
            variant={"outline"}
          >
            <Flex gap={"3px"} alignItems={"center"}>
              <Icon mr={1} as={AiOutlinePlusSquare} />
              <Text className="savedtext">Bars Added</Text>
            </Flex>
          </Button>
          <Button
            onClick={() => setActiveList("savedBars")} // Set active list to savedBars
            className={
              activeList === "savedBars" ? "button-active" : "button-normal"
            }
            w={isMobileView ? "100%" : ""}
            variant={"outline"}
          >
            <Flex gap={"3px"} alignItems={"center"}>
              <Image
                src={savediconbar}
                width="15px"
                height="28px"
                marginRight="8px"
              />
              <Text className="savedtext">Saved</Text>
            </Flex>
          </Button>

          <Box>
            <Button variant={"outline"} onClick={openAddBarModal}>
              <Flex alignItems={"center"} gap={"10px"}>
                <Icon as={BiPlus} />
                <Text> Add Bar</Text>
              </Flex>
            </Button>
          </Box>
        </Box>
        <Box className="bbodyb">
          <Box mt={1} className="raopisd">
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Box>
                <Text className="filterthgou">
                  Filter through your added and saved bars below
                </Text>
              </Box>

              <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
                <MenuButton
                  variant={"outline"}
                  size={"sm"}
                  as={Button}
                  rightIcon={
                    isMenuOpen ? <ChevronUpIcon /> : <ChevronDownIcon />
                  }
                  onClick={handleMenuToggle}
                >
                  {selectedFilter || "Sort"}
                </MenuButton>
              </Menu>
            </Flex>
          </Box>

          <Box mt={2} boxShadow={"lg"} className="skimmonline">
            {activeList === "addedBars" && <AddedBars />}
            {activeList === "savedBars" && <SavedBarList />}
          </Box>
        </Box>
      </Box>
      {!isMobileView && (
        <Box className="sidertwo">
          <Box className="baseseen">
            <Box bg="white" boxShadow="base" p={4} rounded="md">
              <VStack spacing={3} align="stretch">
                <Avatar size="xl" name={userData?.name} bg="teal.500" />
                <Text fontSize="2xl" fontWeight="bold">
                  {userData?.name}
                </Text>
                <Text fontSize="md">Email: {userData?.email}</Text>
                <Text fontSize="md">User ID: {userId}</Text>
                <Badge variant="solid" colorScheme="green">
                  Active Member
                </Badge>
              </VStack>
            </Box>
          </Box>
          <Divider orientation="horizontal" />
          <Box>
            <Box bg="white" boxShadow="base" p={4} rounded="md">
              <Text fontSize="lg" fontWeight="semibold">
                Account Details
              </Text>
              <Text fontSize="md">
                Registration Date:{" "}
                {new Date(userData?.registrationDate).toLocaleDateString()}
              </Text>
            </Box>
          </Box>
          <Box>
            <Box bg="white" boxShadow="base" p={4} rounded="md">
              <Text fontSize="lg" fontWeight="semibold">
                Delete Account
              </Text>
              <Button onClick={() => setIsConfirmDeleteModalOpen(true)}>
                Delete
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      <Modal
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmDeleteModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Account Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to delete your account? All data related to
            your account will be deleted. This action cannot be undone!
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={() => setIsConfirmDeleteModalOpen(false)}>
              Cancel
            </Button>
            <Button colorScheme="red" onClick={deleteAccount}>
              Delete Account
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {isMobileView && (
        <Box mb={"90px"} w={"100%"}>
          <Box bg="white" boxShadow="base" p={4} rounded="md">
            <Text mb={2} fontSize="lg" fontWeight="semibold">
              Delete Account
            </Text>
            <Button onClick={() => setIsConfirmDeleteModalOpen(true)}>
              Delete
            </Button>
          </Box>
        </Box>
      )}

      <Modal isOpen={isAddBarModalOpen} onClose={closeAddBarModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Flex className="aospdua" alignItems={"center"}>
              <Box mb={1}>
                <Image
                  ml={isMobileView ? 1 : 0}
                  src={BarPostIcon}
                  width="50px"
                  marginRight="8px"
                />{" "}
              </Box>{" "}
              <Box>
                <ModalCloseButton />
              </Box>{" "}
            </Flex>
          </ModalBody>

          <Box>
            <BarUpload
              isMobileView={isMobileView}
              onCloseModal={closeAddBarModal}
            />
          </Box>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Profile;
