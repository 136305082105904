import React, { useState, useEffect } from "react";
import { Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import "./StatenIsland.css";
import StatenIslandBarList from "../../Components/Lists/StatenIsland/StatenIslandBarList";
import { Helmet } from "react-helmet";

const StatenIsland = () => {
  const [resetFlag, setResetFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Staten Island Bars | Bars in NYC</title>
        <meta
          name="description"
          content="Staten Island bars. Explore bars in St. George, bars in Tompkinsville, bars in Stapleton, bars in Rosebank, and more. Experience a wide range of Staten Island bars."
        />
      </Helmet>

      <Box className="home-contaner">
        <Box className="sim3q">
          <Box w={"100%"} className="barlist">
            <section>
              <StatenIslandBarList
                searchQuery={searchQuery}
                resetFlag={resetFlag}
              />
            </section>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default StatenIsland;
