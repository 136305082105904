import React, { useState, useEffect } from "react";
import { Box, Flex, Heading, Text, VStack, Image } from "@chakra-ui/react";
import Searching from "../../Components/Search/Searching";
import MainBarList from "../../Components/Lists/MainBarList";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import BrooklynHomeList from "../../Components/ListV2/V2/BrooklynHomeList";
import QueensHomeList from "../../Components/ListV2/V2/QueensHomeList";
import NycBarsHomeList from "../../Components/ListV2/V2/NycBarsHomeList";
import axios from "axios";
import SearchNYC from "../../Components/Search/V2/NYC/SearchNYC";
import { API_ENDPOINT } from "../../Config/config";
import SingleBar from "../SingleBar/SingleBar";
import { useSearchedBarAllNyc } from "../../Context/SearchedBarAllNycContext";

const Home = ({ isMobileView }) => {
  return (
    <>
      <Helmet>
        <title>NYC Bars | Bars in NYC</title>
        <meta
          name="description"
          content="NYC bars. Find bars in New York City. From Manhattan bars, to bars in Brooklyn and Queens. Search NYC bars."
        />
      </Helmet>

      <Box className="home-contaner">
        <Box className="sim3q">
          <Box className="powsosy">
            <Box className="swjqa">
              <NycBarsHomeList isMobileView={isMobileView} />
            </Box>
            <Box mt={2} className="swjqa">
              <BrooklynHomeList isMobileView={isMobileView} />
            </Box>
            <Box mt={2} className="swjqaqueens">
              <QueensHomeList isMobileView={isMobileView} />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Home;
