import React, { useState, useEffect } from "react";
import "./NycBarsHomeList.css";
import { Box, Text, Button, Icon, Flex } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsArrowUpRightCircle } from "react-icons/bs";
import { TbCircleLetterM } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import { API_ENDPOINT } from "../../../Config/config";
import MainBarItem from "../../BarItem/v2/MainBarItem";

const NycBarsHomeList = ({ isMobileView }) => {
  const [bars, setBars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility

  const navigate = useNavigate();

  const fetchBars = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/api/getManhattanBarsLimited`
      );
      // Access the 'data' property of the response object to get the array
      setBars(response.data.data); // Adjusted to match the structure of your response
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching bars:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBars();
  }, []);

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    rtl: true, // Add this line to enable right-to-left sliding
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleNavigate = () => {
    navigate("/bars-in/Manhattan");
    window.scrollTo(0, 0);
  };

  return (
    <Box className="brooklynsk">
      <Box mb={2} className="nowuesy">
        <Flex alignItems={"center"} gap={"5px"}>
          <Icon
            fontSize={isMobileView ? "34px" : "30px"}
            color={"red"}
            as={TbCircleLetterM}
          />
          <Text className="nipsye">Manhattan Bars</Text>
        </Flex>
        <Flex alignItems={"center"} gap={"8px"}>
          {isMobileView ? (
            <>
              <Button
                onClick={handleNavigate}
                bg={"white"}
                variant={"outline"}
                size={"sm"}
              >
                <Text className="bdays">
                  Bars In Nyc <Icon ml={2} as={BsArrowUpRightCircle} />{" "}
                </Text>
              </Button>
            </>
          ) : (
            <>
              <Button
                size={"sm"}
                bg={"white"}
                variant={"outline"}
                _hover={{
                  transform: "scale(1.1)", // Scale icon size when hovered
                  boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
                  backgroundColor: "rgba(60, 242, 78, 0.1)",
                }}
                onClick={handleNavigate}
              >
                Bars In Nyc <Icon ml={2} as={BsArrowUpRightCircle} />{" "}
              </Button>
            </>
          )}
        </Flex>
      </Box>
      <Slider className="evenslider" {...sliderSettings}>
        {bars.map((bar, index) => (
          <>
            <Box key={index}>
              <MainBarItem bar={bar} isMobileView={isMobileView} />
            </Box>
          </>
        ))}
      </Slider>
    </Box>
  );
};

export default NycBarsHomeList;
