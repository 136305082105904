import React, { useState, useEffect } from "react";
import { Box, Flex, Heading, Text, VStack } from "@chakra-ui/react";
import Searching from "../../Components/Search/Searching";
import MainBarList from "../../Components/Lists/MainBarList";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./Bronx.css";
import BronxBarList from "../../Components/Lists/BronxBarList/BronxBarList";
import { Helmet } from "react-helmet";

const Bronx = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const [bars, setBars] = useState([]); // Define the bars state variable
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();

  const [selectedNeighborhood, setSelectedNeighborhood] = useState("");

  const [selectedArea, setSelectedArea] = useState("");

  const handleSearch = (query) => {
    setHasSearched(true);
    setSearchQuery(query);
    setResetFlag(false);
    navigate(`/bars-in/Bronx/?location=${query}`);
  };

  const handleClearSearch = () => {
    setResetFlag(true);
    setSearchQuery("");
    setHasSearched(false);
    setIsLoading(true); // Set loading to true when clearing
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Bronx Bars | Bars in NYC</title>
        <meta
          name="description"
          content="Bars in Bronx. Discover bars in Riverdale, bars in Kingsbridge, bars in Fordham, bars in Mott Haven, and more. Explore a variety of Bronx bars."
        />
      </Helmet>
      <Box className="home-contaner">
        <Box className="sim3q">
          <Box w={"100%"} className="barlist">
            <section>
              <BronxBarList searchQuery={searchQuery} resetFlag={resetFlag} />
            </section>
          </Box>
        </Box>
        <footer>
          <Footer />
        </footer>
      </Box>
    </>
  );
};

export default Bronx;
