import React from "react";
import "./SocialShare.css";
import { Box, Flex, IconButton, Input } from "@chakra-ui/react";
import { BsInstagram, BsTiktok, BsX } from "react-icons/bs";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";

const SocialShare = ({ barName }) => {
  return (
    <Box mb={2}>
      <Flex
        className="frnchy"
        justifyContent="space-around"
        alignItems="center"
        marginBottom="10px"
      >
        <IconButton
          icon={<FaSquareXTwitter />}
          size={"lg"}
          borderRadius={"50%"}
          className="twitter-icon"
          aria-label="Share on Twitter"
        />
        <IconButton
          icon={<FaFacebook />}
          size={"lg"}
          borderRadius={"50%"}
          className="facebook-icon"
          aria-label="Share on Facebook"
        />
        <IconButton
          icon={<BsInstagram />}
          size={"lg"}
          borderRadius={"50%"}
          className="instagram-icon"
          aria-label="Share on Instagram"
        />
        <IconButton
          icon={<BsTiktok />}
          size={"lg"}
          borderRadius={"50%"}
          className="tiktok-icon"
          aria-label="Share on TikTok"
        />
      </Flex>
      <Box mt={5}>
        <Input value={barName} w={"100%"} borderRadius={"12px"} />
      </Box>
    </Box>
  );
};

export default SocialShare;
