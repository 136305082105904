import React, { useState } from "react";
import "./Login.css";
import {
  Box,
  Input,
  Button,
  VStack,
  Flex,
  Icon,
  Text,
  useToast,
} from "@chakra-ui/react";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import { useCookies } from "react-cookie";
import { API_ENDPOINT } from "../../Config/config";

const Login = ({ closeModal }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    recaptchaToken: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [cookies, setCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const toast = useToast();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleRecaptcha = (token) => {
    setFormData({ ...formData, recaptchaToken: token });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}/api/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      if (response.ok) {
        setCookie("AuthToken", data.token);
        setCookie("UserId", data.userId);
        setCookie("Name", data.name);
        setCookie("Email", data.email);
        closeModal();
        toast({
          title: "Login Successful",
          description: data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Login Failed",
          description: data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Network Error",
        description: "Unable to login. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return (
    <Box className="contlogin" boxShadow="md" p="2" rounded="md" bg="white">
      <VStack spacing={4}>
        <Input
          name="email"
          placeholder="Email"
          type="email"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          onChange={handleChange}
          value={formData.email}
        />
        <Input
          name="password"
          placeholder="Password"
          type="password"
          borderColor="gray.300"
          _hover={{ borderColor: "gray.400" }}
          onChange={handleChange}
          value={formData.password}
        />
        <Box>
          <Flex alignItems={"center"} justifyContent={"center"} w={"100%"}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecaptcha}
            />
          </Flex>
        </Box>

        <VStack w="100%" alignItems={"center"} gap={"10px"}>
          <Button
            isLoading={isLoading}
            loadingText="Logging in..."
            onClick={handleSubmit}
            w="100%"
            colorScheme="green"
          >
            <Flex gap={"8px"} alignItems={"center"}>
              <Text>Login</Text>
              {!isLoading && <Icon as={BsArrowUpRightCircleFill} />}
            </Flex>
          </Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Login;
