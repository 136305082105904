import React, { useState, useRef } from "react";
import {
  Box,
  Input,
  Button,
  FormControl,
  FormLabel,
  Image,
  Select,
  Flex,
  useToast,
  Icon,
  Text,
  Spinner,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { TbCircleCheckFilled, TbRotate } from "react-icons/tb";
import { useBars } from "../../../Context/BarContext";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { FaTimes, FaTimesCircle, FaChevronRight } from "react-icons/fa";
import { API_ENDPOINT } from "../../../Config/config";

const ManualAddBar = ({ isMobileView, onCloseModal }) => {
  const [barData, setBarData] = useState({
    name: "",
    business_status: "",
    geometry: "",
    icon: "",
    icon_background_color: "",
    icon_mask_base_uri: "",
    opening_hours: "",
    place_id: "",
    plus_code: "",
    price_level: "",
    rating: "",
    reference: "",
    scope: "",
    types: "",
    user_ratings_total: "",
    vicinity: "",
  });
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [cookies] = useCookies(["Name", "UserId"]); // Adjust the cookie name as needed
  const userName = cookies.Name;
  const userId = cookies.UserId;

  const handleChange = (e) => {
    setBarData({ ...barData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const resetImagePreview = () => {
    setFile(null);
    setImagePreview(null);
  };

  const { bars, fetchBars } = useBars(); // Use the useBars hook

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in barData) {
      formData.append(key, barData[key]);
    }
    formData.append("image", file);
    formData.append("userName", userName);
    formData.append("userId", userId);
    formData.append("borough", selectedFilter); // Add the selected borough to formData

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/add-individual-bar`, // Update the endpoint
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast({
        title: "Bar added successfully.",
        description: "The new bar has been added to the selected borough.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      fetchBars();
      onCloseModal();
    } catch (error) {
      toast({
        title: "Failed to add bar.",
        description: "There was an error processing your request.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const toast = useToast();

  const [selectedFilter, setSelectedFilter] = useState(""); // <-- new state variable to hold the selected filter

  const clearFilter = () => {
    setSelectedFilter("");
  };
  return (
    <div>
      <form>
        <FormControl className="braxovo">
          <Flex
            gap={"20px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={isMobileView ? "column" : "row"}
            h={isMobileView ? "" : "90px"}
          >
            <Flex
              w={"100%"}
              flexDirection={"column"}
              alignItems={"start"}
              justifyContent={"start"}
            >
              <FormLabel className="masterssd">Borough</FormLabel>
              <Menu w={"100%"}>
                {({ isOpen }) => (
                  <>
                    <Flex
                      w={isMobileView ? "100%" : "100%"}
                      alignItems="center"
                      gap={2}
                    >
                      <MenuButton
                        w={"100%"}
                        as={Button}
                        rightIcon={
                          <FaChevronRight
                            style={{
                              transition: "transform 0.2s",
                              transform: isOpen ? "rotate(90deg)" : "none",
                            }}
                          />
                        }
                      >
                        {selectedFilter || "Select Borough"}
                      </MenuButton>
                      {selectedFilter && (
                        <IconButton
                          aria-label="Reset Filter"
                          icon={<Icon as={FaTimesCircle} color="red.500" />}
                          onClick={clearFilter}
                          size="md"
                          variant="outline"
                          colorScheme="red"
                        />
                      )}
                    </Flex>
                    <MenuList>
                      <MenuItem onClick={() => setSelectedFilter("manhattan")}>
                        Manhattan
                      </MenuItem>
                      <MenuItem onClick={() => setSelectedFilter("brooklyn")}>
                        Brooklyn
                      </MenuItem>
                      <MenuItem onClick={() => setSelectedFilter("queens")}>
                        Queens
                      </MenuItem>
                      <MenuItem onClick={() => setSelectedFilter("bronx")}>
                        Bronx
                      </MenuItem>
                      <MenuItem
                        onClick={() => setSelectedFilter("statenIsland")}
                      >
                        Staten Island
                      </MenuItem>
                    </MenuList>
                  </>
                )}
              </Menu>
            </Flex>
            <Flex
              w={"100%"}
              flexDirection={"column"}
              alignItems={"start"}
              justifyContent={"start"}
            >
              {" "}
              <FormLabel className="masterssd">Bar Name</FormLabel>
              <Input name="name" value={barData.name} onChange={handleChange} />
            </Flex>
          </Flex>

          <Flex
            gap={"20px"}
            alignItems={"center"}
            justifyContent={"space-between"}
            flexDirection={isMobileView ? "column" : "row"}
            h={isMobileView ? "" : "90px"}
          >
            <Flex
              w={"100%"}
              flexDirection={"column"}
              alignItems={"start"}
              justifyContent={"start"}
            >
              {imagePreview ? (
                <Box className="wryyat">
                  <Box className="outlineimga">
                    <Image
                      src={imagePreview}
                      alt="Bar Preview"
                      className="vantsre"
                      objectFit="cover"
                    />
                  </Box>
                  <Box>
                    <Button
                      color={"red"}
                      variant={"outline"}
                      size={"xs"}
                      fontSize={"12px"}
                      fontWeight={"600"}
                      onClick={resetImagePreview}
                    >
                      Reset Image
                    </Button>
                  </Box>
                </Box>
              ) : (
                <>
                  <FormLabel className="masterssd">Image Upload</FormLabel>
                  <Input name="image" type="file" onChange={handleFileChange} />
                </>
              )}
            </Flex>
            <Flex
              w={"100%"}
              flexDirection={"column"}
              alignItems={"start"}
              justifyContent={"start"}
            >
              <FormLabel className="masterssd">Address</FormLabel>
              <Input
                name="vicinity"
                value={barData.vicinity}
                onChange={handleChange}
              />
            </Flex>
          </Flex>
          <Accordion mt={2} allowToggle>
            <AccordionItem>
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    Additional Data
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box className="incollapse">
                  <FormLabel>Business Status</FormLabel>
                  <Input
                    name="business_status"
                    value={barData.business_status}
                    onChange={handleChange}
                  />

                  <FormLabel>Geometry (JSON format)</FormLabel>
                  <Textarea
                    name="geometry"
                    value={barData.geometry}
                    onChange={handleChange}
                    placeholder='{"location": {"lat": "", "lng": ""}, "viewport": {"northeast": {"lat": "", "lng": ""}, "southwest": {"lat": "", "lng": ""}}}'
                  />

                  <FormLabel>Icon URL</FormLabel>
                  <Input
                    name="icon"
                    value={barData.icon}
                    onChange={handleChange}
                  />

                  <FormLabel>Icon Background Color</FormLabel>
                  <Input
                    name="icon_background_color"
                    value={barData.icon_background_color}
                    onChange={handleChange}
                  />

                  <FormLabel>Icon Mask Base URI</FormLabel>
                  <Input
                    name="icon_mask_base_uri"
                    value={barData.icon_mask_base_uri}
                    onChange={handleChange}
                  />

                  <FormLabel>Opening Hours (JSON format)</FormLabel>
                  <Textarea
                    name="opening_hours"
                    value={barData.opening_hours}
                    onChange={handleChange}
                    placeholder='{"open_now": false}'
                  />

                  <FormLabel>Place ID</FormLabel>
                  <Input
                    name="place_id"
                    value={barData.place_id}
                    onChange={handleChange}
                  />

                  <FormLabel>Plus Code (JSON format)</FormLabel>
                  <Textarea
                    name="plus_code"
                    value={barData.plus_code}
                    onChange={handleChange}
                    placeholder='{"compound_code": "", "global_code": ""}'
                  />

                  <FormLabel>Price Level</FormLabel>
                  <Input
                    name="price_level"
                    type="number"
                    value={barData.price_level}
                    onChange={handleChange}
                  />

                  <FormLabel>Rating</FormLabel>
                  <Input
                    name="rating"
                    type="number"
                    step="0.1"
                    value={barData.rating}
                    onChange={handleChange}
                  />

                  <FormLabel>Reference</FormLabel>
                  <Input
                    name="reference"
                    value={barData.reference}
                    onChange={handleChange}
                  />

                  <FormLabel>Scope</FormLabel>
                  <Input
                    name="scope"
                    value={barData.scope}
                    onChange={handleChange}
                  />

                  <FormLabel>Types (CSV format)</FormLabel>
                  <Input
                    name="types"
                    value={barData.types}
                    onChange={handleChange}
                    placeholder="bar,restaurant"
                  />

                  <FormLabel>User Ratings Total</FormLabel>
                  <Input
                    name="user_ratings_total"
                    type="number"
                    value={barData.user_ratings_total}
                    onChange={handleChange}
                  />
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </FormControl>
        <Button
          onClick={handleSubmit}
          type="submit"
          marginTop="4"
          colorScheme="blue"
          w={isMobileView ? "100%" : ""}
        >
          Submit Bar Information
        </Button>
      </form>
    </div>
  );
};

export default ManualAddBar;
