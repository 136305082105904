import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  SimpleGrid,
  Icon,
} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import BarItem from "../../BarItem/BarItem";
import { RiHeartPulseFill } from "react-icons/ri";
import { API_ENDPOINT } from "../../../Config/config";

const SavedBarList = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const userName = cookies.Name;
  const [likedBars, setLikedBars] = useState([]);

  useEffect(() => {
    if (userId) {
      axios
        .get(`${API_ENDPOINT}/api/user/${userId}/likedBars`)
        .then((response) => {
          setLikedBars(response.data.data); // Assuming the data comes in response.data.data
        })
        .catch((error) => console.error("Failed to fetch liked bars:", error));
    }
  }, [userId]);

  if (!likedBars || likedBars.length === 0) {
    return (
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        h="200px"
        border="2px dashed gray"
        borderRadius="lg"
        bg="gray.50"
        boxShadow="xl"
        textAlign="center"
      >
        <VStack h={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Icon as={RiHeartPulseFill} w={10} h={10} color="gray.600" />
          <Heading size="md">You haven't Liked any bars yet</Heading>
          <Text>Start saving your favorite bars to see them here!</Text>
        </VStack>
      </Box>
    );
  } else {
    return (
      <SimpleGrid columns={{ base: 2, md: 4 }} spacing={6}>
        {likedBars.map((bar) => (
          <BarItem likedBars={likedBars} key={bar._id} bar={bar} />
        ))}
      </SimpleGrid>
    );
  }
};

export default SavedBarList;
