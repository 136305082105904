import {
  Flex,
  Heading,
  useDisclosure,
  Box,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Image,
  Icon,
  Button,
  Tooltip,
  Link,
  Modal,
  ModalOverlay,
  useToast,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  Avatar,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import "./Header.css";
import GroupIcon from "../../Svg/Group.svg"; // Adjust the path to where your SVG is located
import {
  TbCircleLetterB,
  TbCircleLetterM,
  TbCircleLetterQ,
  TbCircleLetterS,
  TbLogout,
  TbUserCircle,
} from "react-icons/tb";
import { BsArrowUpRightCircle, BsDoorOpen } from "react-icons/bs";
import CreateAccount from "../Auth/CreateAccount";
import Login from "../Auth/Login";
import { useCookies } from "react-cookie";
import { useSearchedBarAllNyc } from "../../Context/SearchedBarAllNycContext";
import { useResetSearch } from "../../Context/ResetSearchContext";

const Header = ({ onNeighborhoodClick }) => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setSearchedSelectedBarAllNyc } = useSearchedBarAllNyc();

  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);

  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);
  const toast = useToast();

  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const userName = cookies.Name;
  const userEmail = cookies.Email;

  const {
    isOpen: isLoginOpen,
    onOpen: onLoginOpen,
    onClose: onLoginClose,
  } = useDisclosure();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const location = useLocation();
  const [selectedNav, setSelectedNav] = useState("/");

  useEffect(() => {
    setSelectedNav(location.pathname);
  }, [location]);

  const handleLogout = () => {
    removeCookie("AuthToken");
    removeCookie("UserId");
    removeCookie("Name");
    removeCookie("Email");
    setIsLogoutConfirmOpen(false); // Close the logout confirmation modal
    toast({
      title: "Logged Out",
      description: "You are successfully signed out.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    navigate("/");
  };

  const toProfile = () => {
    navigate("/user/profile");
    window.scrollTo(0, 0);
  };

  const { searchValueContext, setSearchValueContext, resetSearchContext } =
    useResetSearch();

  const homeAndClose = () => {
    setSearchedSelectedBarAllNyc(null);
    navigate("/");
    setSearchValueContext(""); // Reset the context's search value to an empty string
  };

  const handleNavigation = (path) => {
    setSearchedSelectedBarAllNyc(null);
    setSearchValueContext(""); // Reset the context's search value to an empty string
    navigate(path);
  };

  const openLogoutConfirm = () => setIsLogoutConfirmOpen(true);
  const closeLogoutConfirm = () => setIsLogoutConfirmOpen(false);

  return (
    <Flex
      as="header"
      position="fixed"
      top="0"
      left="0"
      right="0"
      h={isMobileView ? "8%" : "6%"}
      bg="rgba(46, 83, 129, 1)"
      color="rgba(15, 63, 98, 1)"
      alignItems="center"
      px={isMobileView ? "15px" : "20px"}
      zIndex="9000"
      justifyContent={"space-between"}
      boxShadow={"md"}
    >
      <Heading
        _hover={{
          color: "teal.300",
          cursor: "pointer",
          transform: "scale(1.05)",
          boxShadow: "lg",
        }}
        display={"flex"}
        alignItems={"center"}
        size="md"
        color={"white"}
        onClick={homeAndClose}
        className={`${selectedNav === "/" ? "logoactiveLink" : ""}`}
      >
        <Image src={GroupIcon} width="20px" height="33px" marginRight="8px" />{" "}
        Nyc Bars
      </Heading>

      <Flex
        alignItems={"center"}
        as="nav"
        color={"white"}
        gap={"25px"}
        className="headerflex"
      >
        <ul>
          <Link
            border={"none"}
            _hover={{
              color: "rgb(31, 228, 228)",
              cursor: "pointer",
              transform:
                selectedNav !== "/bars-in/Manhattan"
                  ? "scale(0.90) translateY(-1px)"
                  : "none",
            }}
            className={`hauetys ${
              selectedNav === "/bars-in/Manhattan" ? "activeLink" : ""
            }`}
          >
            <Text onClick={() => handleNavigation("/bars-in/Manhattan")}>
              Manhattan
            </Text>
          </Link>
          <Link
            border={"none"}
            _hover={{
              color: "rgb(31, 228, 228)",
              cursor: "pointer",
              transform:
                selectedNav !== "/bars-in/Brooklyn"
                  ? "scale(0.90) translateY(-1px)"
                  : "none",
            }}
            className={`hauetys ${
              selectedNav === "/bars-in/Brooklyn" ? "activeLink" : ""
            }`}
          >
            <Text onClick={() => handleNavigation("/bars-in/Brooklyn")}>
              Brooklyn
            </Text>
          </Link>
          <Link
            border={"none"}
            _hover={{
              color: "rgb(31, 228, 228)",
              cursor: "pointer",
              transform:
                selectedNav !== "/bars-in/Queens"
                  ? "scale(0.90) translateY(-1px)"
                  : "none",
            }}
            className={`hauetys ${
              selectedNav === "/bars-in/Queens" ? "activeLink" : ""
            }`}
          >
            <Text onClick={() => handleNavigation("/bars-in/Queens")}>
              Queens
            </Text>
          </Link>
          <Link
            border={"none"}
            _hover={{
              color: "rgb(31, 228, 228)",
              cursor: "pointer",
              transform:
                selectedNav !== "/bars-in/Bronx"
                  ? "scale(0.90) translateY(-1px)"
                  : "none",
            }}
            className={`hauetys ${
              selectedNav === "/bars-in/Bronx" ? "activeLink" : ""
            }`}
          >
            <Text onClick={() => handleNavigation("/bars-in/Bronx")}>
              Bronx
            </Text>
          </Link>
          <Link
            border={"none"}
            _hover={{
              color: "rgb(31, 228, 228)",
              cursor: "pointer",
              transform:
                selectedNav !== "/bars-in/Staten-Island"
                  ? "scale(0.90) translateY(-1px)"
                  : "none",
            }}
            className={`hauetys ${
              selectedNav === "/bars-in/Staten-Island" ? "activeLink" : ""
            }`}
          >
            <Text onClick={() => handleNavigation("/bars-in/Staten-Island")}>
              Staten Island
            </Text>
          </Link>
        </ul>
        <Box>
          {AuthToken ? (
            <>
              <Menu>
                <Tooltip
                  borderRadius={"8px"}
                  label="Your Account"
                  placement="bottom"
                >
                  <MenuButton
                    as={Button}
                    rounded={"full"}
                    variant={"link"}
                    cursor={"pointer"}
                    minW={0}
                    border={"1px solid transparent"}
                    boxShadow={"0 4px 8px 2px rgba(0, 0, 0, 0.1)"}
                    _hover={{
                      border: "1px solid white",
                    }}
                  >
                    <Flex p={1} gap={"6px"} alignItems={"center"}>
                      <Avatar size={"xs"} name={userName} />
                      <Text className="aoetsy">{userEmail}</Text>
                    </Flex>
                  </MenuButton>
                </Tooltip>

                <MenuList className="gaushy">
                  <MenuItem className="mentions" onClick={toProfile}>
                    <Icon mr={2} className="auiosyuyts" as={TbUserCircle} />
                    Profile
                  </MenuItem>
                  <MenuItem
                    className="logoutmentions"
                    colorScheme="red"
                    onClick={openLogoutConfirm}
                  >
                    <Icon mr={2} className="auiosyuyts" as={TbLogout} />
                    Logout
                  </MenuItem>
                </MenuList>
              </Menu>
            </>
          ) : (
            <>
              {" "}
              <Tooltip
                borderRadius={"8px"}
                label="Create an account"
                placement="bottom"
              >
                <Button
                  _hover={{
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                  _active={{
                    transform: "scale(0.95)",
                  }}
                  mr={3}
                  colorScheme="cyan"
                  size={"sm"}
                  onClick={onOpen}
                >
                  Join!
                </Button>
              </Tooltip>
              <Tooltip
                borderRadius={"8px"}
                label="Login to your account"
                placement="bottom"
              >
                <Button
                  _hover={{
                    transform: "scale(1.05)",
                    boxShadow: "lg",
                  }}
                  _active={{
                    transform: "scale(0.95)",
                  }}
                  colorScheme="green"
                  size={"sm"}
                  onClick={onLoginOpen}
                >
                  <Flex gap={"4px"} alignItems={"center"}>
                    <Text>Login</Text>
                    <Icon as={BsArrowUpRightCircle} />
                  </Flex>
                </Button>
              </Tooltip>
            </>
          )}
        </Box>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent className="makemodal">
          <Box className="trebbbaj">
            <Flex gap={"10px"} alignItems={"center"}>
              <Text className="platertuio">Welcome!</Text>
              <Icon className="raaamsp" as={BsDoorOpen} />
            </Flex>
          </Box>
          <ModalCloseButton />
          <CreateAccount isMobileView={isMobileView} closeModal={onClose} />
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isLoginOpen} onClose={onLoginClose}>
        <ModalOverlay />
        <ModalContent className="makemodal">
          <ModalHeader>Login to Your Account</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Login closeModal={onLoginClose} />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isLogoutConfirmOpen} onClose={closeLogoutConfirm}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Flex gap={"10px"} alignItems={"center"}>
              <Text>Confirm Logout</Text>
              <Icon as={TbLogout} />
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>Are you sure you want to sign out?</ModalBody>
          <ModalFooter>
            <Button
              w={"100%"}
              colorScheme="gray"
              mr={3}
              onClick={closeLogoutConfirm}
            >
              Cancel
            </Button>
            <Button w={"100%"} colorScheme="red" onClick={handleLogout}>
              Yes, Sign Out
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default Header;
