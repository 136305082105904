import React, { useEffect, useState } from "react";
import {
  Box,
  HStack,
  Heading,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./PrivacyPolicy.css";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box className="privacy" bg="gray.100" minHeight="100vh">
      <Helmet>
        <title>Privacy Policy | NYC Bar Finder</title>
        <meta
          name="description"
          content="Review NYC Bar Finder's Privacy Policy. Learn about how we handle your data, our use of cookies, third-party interactions, and more. We're committed to maintaining the privacy and protection of our users."
        />
      </Helmet>
      <Header />
      <VStack spacing={isMobileView ? 6 : 6} align="start">
        <Heading mb={3} as="h1" size="lg">
          Privacy Policy
        </Heading>

        <Heading as="h2" size="md">
          1. Consent
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          We are committed to respecting your privacy and protecting your
          personal data. This Privacy Policy outlines how we collect, use, and
          safeguard your information. By using our website, you hereby consent
          to this Privacy Policy and agree to its terms.
        </Text>

        <Heading as="h2" size="md">
          2. Data Collection
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          We may collect information when you perform a search for bars in New
          York City's 5 boroughs, but we do not store any personal
          identification details unless explicitly provided by the user for
          other functionalities of the site.
        </Text>

        <Heading as="h2" size="md">
          3. Use of Data
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          The data we collect is primarily used to enhance your browsing
          experience and improve our services. We neither sell nor rent your
          data to third parties.
        </Text>

        <Heading as="h2" size="md">
          4. Cookies and Web Beacons
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          We utilize cookies and similar tracking technologies to monitor
          activity on our website and gather certain data. This aids in
          enhancing your user experience and for our analytical assessments.
        </Text>

        <Heading as="h2" size="md">
          5. Google AdSense
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          We employ Google AdSense to display ads on our platform. Google uses
          cookies to personalize ads based on your previous visits to our
          website and other online platforms. You have the option to opt out of
          personalized ads by visiting Google's Ad Settings.
        </Text>

        <Heading as="h2" size="md">
          6. Third-Party Services
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          We may collaborate with third-party services to enhance our platform.
          These third parties have access to site data solely for performing
          specific tasks on our behalf and are obligated not to disclose or
          utilize it for other purposes.
        </Text>

        <Heading as="h2" size="md">
          7. Security
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          Your trust is important to us. While we employ commercially acceptable
          methods to protect your data, please be aware that no electronic
          transmission method or storage solution is entirely secure.
        </Text>

        <Heading as="h2" size="md">
          8. Links to Other Sites
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          Our platform may contain links to external sites. We advise you to
          review the Privacy Policies of these external websites, as we do not
          control and are not responsible for their content or practices.
        </Text>

        <Heading as="h2" size="md">
          9. Changes to Privacy Policy
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          Our Privacy Policy may be updated periodically. We recommend checking
          this page regularly for any modifications.
        </Text>

        <Heading as="h2" size="md">
          10. Contact Us
        </Heading>
        <Text mb={isMobileView ? 8 : 8} fontWeight={300} textAlign={"start"}>
          If you have any questions or concerns about our Privacy Policy, please
          contact us at contactnycbarfinder@nycbarfinder.com.
        </Text>
      </VStack>
      <Footer />
    </Box>
  );
};

export default PrivacyPolicy;
