import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  Button,
  SimpleGrid,
  Icon,
  Skeleton,
  Spinner,
  Center,
} from "@chakra-ui/react";
import "./AddedBars.css";
import BarItem from "../../BarItem/BarItem";
import { useBars } from "../../../Context/BarContext";
import { useCookies } from "react-cookie";
import { IconBase } from "react-icons/lib";
import { FaRegSadTear } from "react-icons/fa"; // Import an icon for the empty state
import { API_ENDPOINT } from "../../../Config/config";

const AddedBars = () => {
  const { bars, fetchBars, isLoading } = useBars(); // Use the useBars hook
  const [userLikes, setUserLikes] = useState([]);
  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const userName = cookies.Name;

  useEffect(() => {
    fetchBars(); // Fetch bars on component mount
    fetchUserLikes();
  }, []);

  const sortBarsByNewest = (barsArray) => {
    return barsArray.sort(
      (a, b) => new Date(b.uploadDate) - new Date(a.uploadDate)
    );
  };

  const fetchUserLikes = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/api/user/${userId}`, {
        headers: { Authorization: `Bearer ${cookies.AuthToken}` },
      });
      setUserLikes(response.data.likes || []);
    } catch (error) {
      console.error("Error fetching user likes:", error);
    }
  };

  // Sort bars by newest before rendering
  const sortedBars = sortBarsByNewest(bars);

  if (!isLoading && sortedBars.length === 0) {
    return (
      <Box
        d="flex"
        justifyContent="center"
        alignItems="center"
        h="200px"
        border="2px dashed gray"
        borderRadius="lg"
        bg="gray.50"
        boxShadow="xl"
        textAlign="center"
      >
        <VStack h={"100%"} justifyContent={"center"} alignItems={"center"}>
          <Icon as={FaRegSadTear} w={10} h={10} color="gray.600" />
          <Heading size="md">You haven't added any bars yet</Heading>
          <Text>Start adding your favorite bars to see them here!</Text>
        </VStack>
      </Box>
    );
  }

  return (
    <>
      {isLoading ? (
        <Center>
          <Spinner boxShadow={"lg"} />
        </Center>
      ) : (
        <>
          {" "}
          <SimpleGrid columns={{ base: 2, md: 4 }} spacing={6}>
            {sortedBars.map((bar) => (
              <BarItem
                key={bar._id}
                bar={bar}
                userLikes={userLikes}
                onDelete={fetchBars}
              />
            ))}
          </SimpleGrid>
        </>
      )}
    </>
  );
};

export default AddedBars;
