import React, { useState } from "react";
import { Box } from "@chakra-ui/react";
import Footer from "../../Components/Footer/Footer";
import { useNavigate } from "react-router-dom";
import "./Queens.css";
import QueensBarList from "../../Components/Lists/QueensBarList/QueensBarList";
import { Helmet } from "react-helmet";

const Queens = () => {
  const [hasSearched, setHasSearched] = useState(false);
  const [resetFlag, setResetFlag] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Queens Bars | Bars in NYC</title>
        <meta
          name="description"
          content="Bars in Queens. Explore bars in Astoria, bars in Long Island City, bars in Sunnyside, bars in Flushing, and more. Explore a diverse range of Queens bars."
        />
      </Helmet>
      <Box className="home-contaner">
        <Box className="sim3q">
          <Box w={"100%"} className="barlist">
            <section>
              <QueensBarList searchQuery={searchQuery} resetFlag={resetFlag} />
            </section>
          </Box>
        </Box>
        <footer>
          <Footer />
        </footer>
      </Box>
    </>
  );
};

export default Queens;
