// src/Context/ResetSearchContext.js
import React, { createContext, useContext, useState } from "react";

const ResetSearchContext = createContext();

export const useResetSearch = () => useContext(ResetSearchContext);

export const ResetSearchProvider = ({ children }) => {
  const [searchValueContext, setSearchValueContext] = useState("");

  const resetSearchContext = () => {
    setSearchValueContext(""); // Reset search input
  };

  return (
    <ResetSearchContext.Provider
      value={{ searchValueContext, setSearchValueContext, resetSearchContext }}
    >
      {children}
    </ResetSearchContext.Provider>
  );
};
