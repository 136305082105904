import React, { useEffect } from "react";
import {
  Box,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  PinInput,
  Flex,
  Tooltip,
  Link,
} from "@chakra-ui/react";
import { AiOutlineCopy } from "react-icons/ai";
import { BiPin } from "react-icons/bi";
import "./BarModal.css"; // Adjust the path according to your project structure
import { IconButton } from "@chakra-ui/react";
import { BsGoogle } from "react-icons/bs";
import SingleAffiliateLinks from "../AffiliateLinks/SingleAffiliateLinks";
import { useToast } from "@chakra-ui/react";

const BarModal = ({
  isModalOpen,
  closeModal,
  selectedBar,
  isMobileView,
  GOOGLE_API_KEY,
  renderStars,
}) => {
  const copyAddressToClipboard = () => {
    navigator.clipboard
      .writeText(selectedBar.vicinity)
      .then(() => {
        toast({
          title: "Address Copied!",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
      });
  };
  const toast = useToast();

  return (
    <Modal
      bg="rgba(224, 228, 243, 1)"
      isOpen={isModalOpen}
      onClose={closeModal}
      size={isMobileView ? "md" : "3xl"} // Adjust the size according to your needs
      aria-labelledby="modal-title" // Adding ARIA label
    >
      <ModalOverlay />
      <ModalContent
        className="contentpfmodel"
        position={"relative"}
        backgroundColor="#f9fafb"
        border="2px solid #CBD5E0"
        boxShadow="lg"
        color="#2d3748"
        h={isMobileView ? "89%" : "auto"} // Adjust the height of the modal content according to your needs
      >
        <Box className="imageWithAddress">
          {selectedBar.photos && selectedBar.photos.length > 0 ? (
            <Box className="imagecontainer">
              <Image
                className="imagestyle"
                src={`https://maps.googleapis.com/maps/api/place/photo?maxwidth=400&photoreference=${selectedBar.photos[0].photo_reference}&key=${GOOGLE_API_KEY}`}
                alt={selectedBar.name + " image"} // Alt text for the image
                objectFit="cover"
              />
            </Box>
          ) : (
            <Image src="path/to/default/image.jpg" alt="Default" />
          )}
          <Box className="addresscont">
            <Text mt={1}>{selectedBar.vicinity}</Text>
            {isMobileView && (
              <Text
                onClick={copyAddressToClipboard}
                cursor="pointer"
                color={"blue.500"}
              >
                Copy Address
              </Text>
            )}
            {!isMobileView && (
              <Tooltip borderRadius={"7px"} label="Copy Link" placement="top">
                <IconButton
                  size={"sm"}
                  icon={<AiOutlineCopy />}
                  onClick={copyAddressToClipboard}
                  position="absolute"
                  top={isMobileView ? "30%" : "50%"}
                  right={isMobileView ? "7%" : "7%"}
                  transform="translate(50%, -50%)"
                  /* Other IconButton Props */
                />
              </Tooltip>
            )}
          </Box>
        </Box>

        <ModalHeader
          m={1}
          id="modal-title"
          fontSize={isMobileView ? "21px" : "29px"} // Responsive font size
          color="rgba(21, 58, 85, 1)"
          bg={"rgba(255, 255, 255, 0.8)"} // Soft, neutral background color
          boxShadow="0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -1px rgba(0, 0, 0, 0.06)" // Added subtle shadow for depth
          borderBottom="2px solid #e2e8f0"
          fontWeight={"800"}
          borderRadius={"12px"}
          position={"absolute"}
        >
          {/* {selectedBar.name} */}
          <h2>{selectedBar.name}</h2>
        </ModalHeader>
        <ModalCloseButton
          padding={"5px"}
          fontSize={"22px"}
          mt={1}
          mr={isMobileView ? 0 : 2}
          color={isMobileView ? "white" : "white"}
        />

        <Box className="rating" fontSize="lg" color="#4a5568">
          <Box className="yellow">
            <Text className="ratingtext">
              {renderStars(selectedBar.rating)} out of{" "}
              {selectedBar.user_ratings_total} ratings
            </Text>
          </Box>

          <Box mr={isMobileView ? 0 : 1}>
            <Tooltip
              placement={"top"}
              label="View on Google"
              aria-label="A tooltip"
            >
              <Link isExternal>
                <IconButton
                  onClick={(event) => event.stopPropagation()}
                  display={"flex"}
                  aria-label="Foursquare"
                  icon={
                    <BsGoogle
                      color="blue"
                      fontSize={isMobileView ? "20px" : "20px"}
                    />
                  }
                  variant="unstyled"
                  background="white" // Green background with some transparency
                  borderRadius="10%" // To make it circular
                  p="0" // Padding around the icon
                  boxShadow={"0 4px 8px 2px rgba(0, 0, 0, 0.1)"}
                  _hover={{
                    background: "white", // Darker Green when hovered
                    transform: "scale(1.1)", // Scale icon size when hovered
                    boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
                  }}
                />
              </Link>
            </Tooltip>
          </Box>
        </Box>
        <SingleAffiliateLinks selectedBar={selectedBar} />
      </ModalContent>
    </Modal>
  );
};

export default BarModal;
