// LikedBarsContext.js
import axios from "axios";
import React, { createContext, useState, useContext, useCallback } from "react";
import { API_ENDPOINT } from "../Config/config";

const LikedBarsContext = createContext();

export const useLikedBars = () => useContext(LikedBarsContext);

export const LikedBarsProvider = ({ children }) => {
  const [likedBars, setLikedBars] = useState([]);

  const fetchLikedBars = useCallback(async (userId) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/api/getAllManhattanBars`
      );
      setLikedBars(response.data.data);
    } catch (error) {
      console.error("Error fetching liked bars:", error);
    }
  }, []);

  return (
    <LikedBarsContext.Provider
      value={{ likedBars, setLikedBars, fetchLikedBars }}
    >
      {children}
    </LikedBarsContext.Provider>
  );
};
