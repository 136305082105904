import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Spinner,
  Text,
  Tooltip,
  Image,
  useToast,
  ModalBody,
  ModalHeader,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
} from "@chakra-ui/react";
import AffiliateLinks from "../../AffiliateLinks/AffiliateLinks";
import { AiOutlineCopy } from "react-icons/ai";
import { useCookies } from "react-cookie";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { BsShareFill } from "react-icons/bs";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import BarModal from "../../BarModel/BarModal";
import Login from "../../Auth/Login";
import { StarIcon } from "@chakra-ui/icons";
import SocialShare from "../../Share/SocialShare";
import { BiHeart } from "react-icons/bi";

const MainBarItem = ({ isMobileView, bar }) => {
  const toast = useToast();
  const [showLoginModal, setShowLoginModal] = useState(false); // State for showing the login modal
  const [selectedBar, setSelectedBar] = useState(null); // State to store the selected bar
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isLoading, setIsLoading] = useState(false);
  const [barData, setBarData] = useState([]);

  const [isShareIconButtonModalOpen, setShareIconButtonModalOpen] =
    useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([
    "AuthToken",
    "UserId",
  ]);

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedBar(null);
    window.history.pushState({}, "", "/bars-in/Manhattan"); // Reset URL to the default
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const isHalfStar = rating - fullStars >= 0.5; // Determine if there's a half star
    const halfStars = isHalfStar ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {Array(fullStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`full-${i}`} as={StarIcon} color="yellow.400" />
          ))}
        {Array(halfStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`half-${i}`} as={StarIcon} color="yellow.200" />
          ))}
        {Array(emptyStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`empty-${i}`} as={StarIcon} color="gray.300" />
          ))}
      </>
    );
  };

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "..";
    }
    return text;
  };

  const buildBarUrl = (barName) => {
    return `/bars-in/Manhattan/${encodeURIComponent(barName)}`;
  };

  const handleCopy = (address) => {
    navigator.clipboard
      .writeText(address)
      .then(() => {
        toast({
          title: "Address copied",
          description: `${address} has been copied to your clipboard.`,
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const handleBarClick = (bar) => {
    setSelectedBar(bar);
    setIsModalOpen(true);
    const barUrl = buildBarUrl(bar.name);
    window.history.pushState({ bar: bar.name }, "", barUrl);
  };

  const handleLikeClickOrLogin = (event, barId) => {
    event.stopPropagation(); // Prevent further propagation of the click event

    if (AuthToken) {
      handleLikeClick(barId);
    } else {
      setShowLoginModal(true); // Open the login modal
    }
  };

  const handleShareIconButtonClick = (event, bar) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setShareIconButtonModalOpen(true);
    setSelectedBar(bar); // Set the selected bar
  };

  const userHasLikedBar = () =>
    barData.Likes?.some((like) => like.userId === userId);

  useEffect(() => {
    setBarData(bar);
  }, [bar]);

  const handleLikeClick = async (barId) => {
    setIsLoading(true); // Assuming you have a state [isLoading, setIsLoading] for loading indication

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/user/${userId}/likeBar`,
        {
          userId, // Assuming userId is retrieved from cookies or app context
          barId,
        }
      );

      toast({
        title: "Bar Liked",
        description: "You have successfully liked this bar.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error liking the bar:", error);
      toast({
        title: "Error",
        description: "Erro Liking Bar.",
        status: "Error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box>
      <Box className="askuido">
        <Box className="allitemr">
          <Flex
            className="bentlye"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box className="wiretys">
              <Flex w={"100%"} flexDirection={"column"} alignItems={"start"}>
                <Flex className="qlasi">
                  <Text className="rackofontbig">Bar</Text>
                </Flex>
                {isMobileView ? (
                  <Text className="mainbarbame">
                    {truncateText(bar.name, 21)}
                  </Text>
                ) : (
                  <Text className="mainbarbame">
                    {truncateText(bar.name, 25)}
                  </Text>
                )}
              </Flex>
            </Box>

            <Flex alignItems={"center"} gap={"15px"}>
              {isLoading ? (
                <Spinner size="sm" color="red.500" />
              ) : (
                <Icon
                  as={FaHeart}
                  _hover={{ cursor: "pointer" }}
                  fontSize={"19px"}
                  color={userHasLikedBar() ? "yellow.400" : "cyan.300"}
                  onClick={(event) => handleLikeClickOrLogin(event, bar._id)}
                />
              )}
              <Tooltip
                borderRadius={"8px"}
                mb={1}
                label="Share"
                placement="top"
              >
                <Icon
                  mr={isMobileView ? 2 : 0}
                  as={BsShareFill}
                  color={"blue"}
                  onClick={(event) => handleShareIconButtonClick(event, bar)}
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Box className="allrusio">
            <Image
              src={bar.imageUrl}
              alt={`Image of ${bar.name}`}
              className="robinsimg"
              onClick={() => handleBarClick(bar)}
            />
          </Box>
          <Box className="leounce">
            <Box className="teahsk">
              <Text className="rackofontbig">Address:</Text>
              {isMobileView ? (
                <Text className="mainitemset">
                  {truncateText(bar.vicinity, 30)}
                </Text>
              ) : (
                <Text className="mainitemset">
                  {truncateText(bar.vicinity, 26)}
                </Text>
              )}
            </Box>
            <Box className="picketf">
              <IconButton
                size={"sm"}
                aria-label="Copy address"
                icon={<AiOutlineCopy />}
                onClick={() => handleCopy(bar.vicinity)}
              />
            </Box>
          </Box>
          <Box>{!bar.vicinity ? null : <AffiliateLinks bar={bar} />}</Box>
        </Box>
      </Box>
      <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please Login Below!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Login onClose={() => setShowLoginModal(false)} />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isShareIconButtonModalOpen}
        onClose={() => setShareIconButtonModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share {selectedBar?.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedBar && <SocialShare barName={selectedBar.name} />}
          </ModalBody>
        </ModalContent>
      </Modal>

      {selectedBar && (
        <>
          <BarModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            selectedBar={selectedBar}
            isMobileView={isMobileView}
            GOOGLE_API_KEY={GOOGLE_API_KEY}
            renderStars={renderStars}
          />
        </>
      )}
    </Box>
  );
};

export default MainBarItem;
