import React, { useState } from "react";
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  Image,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useCookies } from "react-cookie";
import { API_ENDPOINT } from "../../../Config/config";

const StatenIslandBarUpload = () => {
  const [barData, setBarData] = useState({
    name: "",
    business_status: "",
    geometry: "",
    icon: "",
    icon_background_color: "",
    icon_mask_base_uri: "",
    opening_hours: "",
    place_id: "",
    plus_code: "",
    price_level: "",
    rating: "",
    reference: "",
    scope: "",
    types: "",
    user_ratings_total: "",
    vicinity: "",
  });
  const [file, setFile] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [cookies] = useCookies(["Name", "UserId"]); // Adjust the cookie name as needed
  const userName = cookies.Name;
  const userId = cookies.UserId;

  const handleChange = (e) => {
    setBarData({ ...barData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFile(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in barData) {
      formData.append(key, barData[key]);
    }
    formData.append("image", file);
    formData.append("userName", userName);
    formData.append("userId", userId);

    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/addStatenIslandBar`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast({
        title: "Bar added successfully.",
        description: "The new Manhattan bar has been added.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to add bar.",
        description: "There was an error processing your request.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const toast = useToast();

  return (
    <div>
      <div>
        <Box className="AddManhattanBar-main" padding="4">
          <form onSubmit={handleSubmit}>
            <FormControl>
              <FormLabel>Name</FormLabel>
              <Input name="name" value={barData.name} onChange={handleChange} />

              <FormLabel>Business Status</FormLabel>
              <Input
                name="business_status"
                value={barData.business_status}
                onChange={handleChange}
              />

              <FormLabel>Geometry (JSON format)</FormLabel>
              <Textarea
                name="geometry"
                value={barData.geometry}
                onChange={handleChange}
                placeholder='{"location": {"lat": "", "lng": ""}, "viewport": {"northeast": {"lat": "", "lng": ""}, "southwest": {"lat": "", "lng": ""}}}'
              />

              <FormLabel>Icon URL</FormLabel>
              <Input name="icon" value={barData.icon} onChange={handleChange} />

              <FormLabel>Icon Background Color</FormLabel>
              <Input
                name="icon_background_color"
                value={barData.icon_background_color}
                onChange={handleChange}
              />

              <FormLabel>Icon Mask Base URI</FormLabel>
              <Input
                name="icon_mask_base_uri"
                value={barData.icon_mask_base_uri}
                onChange={handleChange}
              />

              <FormLabel>Opening Hours (JSON format)</FormLabel>
              <Textarea
                name="opening_hours"
                value={barData.opening_hours}
                onChange={handleChange}
                placeholder='{"open_now": false}'
              />

              <FormLabel>Place ID</FormLabel>
              <Input
                name="place_id"
                value={barData.place_id}
                onChange={handleChange}
              />

              <FormLabel>Plus Code (JSON format)</FormLabel>
              <Textarea
                name="plus_code"
                value={barData.plus_code}
                onChange={handleChange}
                placeholder='{"compound_code": "", "global_code": ""}'
              />

              <FormLabel>Price Level</FormLabel>
              <Input
                name="price_level"
                type="number"
                value={barData.price_level}
                onChange={handleChange}
              />

              <FormLabel>Rating</FormLabel>
              <Input
                name="rating"
                type="number"
                step="0.1"
                value={barData.rating}
                onChange={handleChange}
              />

              <FormLabel>Reference</FormLabel>
              <Input
                name="reference"
                value={barData.reference}
                onChange={handleChange}
              />

              <FormLabel>Scope</FormLabel>
              <Input
                name="scope"
                value={barData.scope}
                onChange={handleChange}
              />

              <FormLabel>Types (CSV format)</FormLabel>
              <Input
                name="types"
                value={barData.types}
                onChange={handleChange}
                placeholder="bar,restaurant"
              />

              <FormLabel>User Ratings Total</FormLabel>
              <Input
                name="user_ratings_total"
                type="number"
                value={barData.user_ratings_total}
                onChange={handleChange}
              />

              <FormLabel>Vicinity</FormLabel>
              <Input
                name="vicinity"
                value={barData.vicinity}
                onChange={handleChange}
              />

              <FormLabel>Image Upload</FormLabel>
              <Input name="image" type="file" onChange={handleFileChange} />
              {imagePreview && (
                <Image
                  src={imagePreview}
                  alt="Bar Preview"
                  boxSize="200px"
                  objectFit="cover"
                  marginTop="4"
                />
              )}
            </FormControl>
            <Button type="submit" marginTop="4" colorScheme="blue">
              Submit Bar Information
            </Button>
          </form>
        </Box>
      </div>
    </div>
  );
};

export default StatenIslandBarUpload;
