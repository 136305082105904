import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import React, { useState, useEffect } from "react";

import { Box, ChakraProvider } from "@chakra-ui/react";
import Home from "../src/Pages/Home/Home";
import PrivacyPolicy from "../src/Pages/PrivacyPolicy/PrivacyPolicy";
import TermsConditions from "../src/Pages/TermsConditions/TermsConditions";
import Manhattan from "./Pages/Manhattan/Manhattan";
import Queens from "./Pages/Queens/Queens";
import Brooklyn from "./Pages/Brooklyn/Brooklyn";
import Bronx from "./Pages/Bronx/Bronx";
import StatenIsland from "./Pages/Staten Island/StatenIsland";
import Contact from "./Pages/Contact/Contact";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import BarModal from "./Components/BarModel/BarModal";
import Profile from "./Pages/Profile/Profile";
import { BarProvider } from "./Context/BarContext"; // Import the BarProvider
import { LikedBarsProvider } from "./Context/LikedBarsContext";
import { ManhattanBarProvider } from "./Context/ManhattanBarContext";
import { SearchedBarProvider } from "./Context/SearchedBarContext";
import SingleBar from "./Pages/SingleBar/SingleBar";
import { SearchedBarAllNycProvider } from "./Context/SearchedBarAllNycContext";
import MainHero from "./Components/Hero/MainHero";
import { AddNewBarProvider } from "./Context/AddNewBarContext";
import MobileHeader from "./Components/Header/MobileHeader";
import { ResetSearchProvider } from "./Context/ResetSearchContext";
import { SingleBarItemProvider } from "./Context/SingleBarItemContext";
import Search from "./Pages/Search/Search";
import { IndividualBarByIdProvider } from "./Context/IndividualBarByIdContext";

function App() {
  const HeroConditional = () => {
    const location = useLocation(); // Get current location
    const showHero = location.pathname !== "/user/profile"; // Determine whether to show MainHero
    return showHero ? <MainHero location={location} /> : null; // Conditionally render MainHero
  };

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <ChakraProvider>
      <BarProvider>
        <LikedBarsProvider>
          <ManhattanBarProvider>
            <IndividualBarByIdProvider>
              <SearchedBarAllNycProvider>
                <SingleBarItemProvider>
                  <ResetSearchProvider>
                    <AddNewBarProvider>
                      <SearchedBarProvider>
                        <Router>
                          {isMobileView ? (
                            <>
                              <MobileHeader isMobileView={isMobileView} />
                            </>
                          ) : (
                            <>
                              <Header />
                            </>
                          )}
                          {/* Use the conditional wrapper here */}
                          <Box className="App">
                            <HeroConditional />
                            <Routes>
                              <Route
                                path="/"
                                exact
                                element={<Home isMobileView={isMobileView} />}
                              />
                              <Route
                                path="/bars-in/Manhattan"
                                exact
                                element={<Manhattan />}
                              />
                              <Route
                                path="/nyc-bars/:borough-bars/:barName"
                                element={<SingleBar />}
                              />

                              <Route
                                path="/search/:borough-bars/:barName"
                                element={<Search />}
                              />

                              <Route
                                path="/bars-in/Queens"
                                exact
                                element={<Queens />}
                              />
                              <Route
                                path="/user/profile"
                                exact
                                element={<Profile />}
                              />

                              <Route
                                path="/bars-in/Brooklyn"
                                exact
                                element={<Brooklyn />}
                              />
                              <Route
                                path="/bars-in/Bronx"
                                exact
                                element={<Bronx />}
                              />
                              <Route
                                path="/bars-in/Staten-Island"
                                exact
                                element={<StatenIsland />}
                              />
                              <Route
                                path="/privacypolicy"
                                exact
                                element={<PrivacyPolicy />}
                              />
                              <Route
                                path="/termsofservice"
                                exact
                                element={<TermsConditions />}
                              />
                              <Route
                                path="/contact"
                                exact
                                element={<Contact />}
                              />
                            </Routes>
                          </Box>
                        </Router>
                      </SearchedBarProvider>
                    </AddNewBarProvider>
                  </ResetSearchProvider>
                </SingleBarItemProvider>
              </SearchedBarAllNycProvider>
            </IndividualBarByIdProvider>
            <Footer />
          </ManhattanBarProvider>
        </LikedBarsProvider>
      </BarProvider>
    </ChakraProvider>
  );
}

export default App;
