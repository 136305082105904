import React, { useState, useRef } from "react";
import {
  Input,
  Button,
  InputGroup,
  InputRightElement,
  Box,
  useToast,
  Icon,
  Text,
  Spinner,
  Skeleton,
  IconButton,
  Flex,
  Tag,
} from "@chakra-ui/react";
import "./SearchAndUpload.css";
import { ArrowRightIcon, SearchIcon } from "@chakra-ui/icons";
import axios from "axios";
import { API_ENDPOINT } from "../../../Config/config";
import { FaCocktail, FaPlus, FaTimes } from "react-icons/fa";
import { useCookies } from "react-cookie";
import { useBars } from "../../../Context/BarContext";
import ManualAddBar from "../ManualUpload/ManualAddBar";
import { FaForumbee } from "react-icons/fa6";
import { BiBorderNone, BiNoEntry } from "react-icons/bi";
import { TbFileUnknown } from "react-icons/tb";
import { BsArrowLeftCircleFill } from "react-icons/bs";

const SearchAndUpload = ({ isMobileView, onCloseModal }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const [isAdding, setIsAdding] = useState(false); // State to track if adding
  const [cookies] = useCookies(["Name", "UserId"]); // Adjust the cookie name as needed
  const userName = cookies.Name;
  const userId = cookies.UserId;

  const [showManualAdd, setShowManualAdd] = useState(false); // New state

  const { bars, fetchBars } = useBars(); // Use the useBars hook

  const toast = useToast();

  const handleBack = () => {
    setShowManualAdd(null);
  };

  const getBoroughBadge = (address) => {
    if (address.includes("Brooklyn")) return "Brooklyn";
    if (address.includes("New York")) return "Manhattan";
    if (address.includes("Queens")) return "Queens";
    if (address.includes("Bronx")) return "Bronx";
    if (address.includes("Staten Island")) return "Staten Island";
    return ""; // Default case if no match found
  };

  const handleSearch = async () => {
    if (searchTerm.trim() === "") {
      toast({
        title: "Please enter a bar name to search.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsLoading(true); // Start loading

    try {
      const response = await axios.get(
        `${API_ENDPOINT}/api/searchBars/${encodeURIComponent(searchTerm)}`
      );
      setIsLoading(false); // End loading

      if (response.data.status === "success") {
        setSearchResults(response.data.data);
        toast({
          title: "Search completed successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          title: "No results found.",
          description: "Please try a different search term.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
        setShowManualAdd(true); // Show manual add form if no results

        setSearchResults([]);
      }
    } catch (error) {
      setIsLoading(false); // End loading on error
      setShowManualAdd(true); // Show manual add form if no results

      toast({
        title: "An error occurred.",
        description: "Unable to perform search. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearchTerm(newSearchTerm);
    if (newSearchTerm.trim() === "" || searchResults.length > 0) {
      setSearchResults([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleClear = () => {
    setSearchResults([]);
    setSearchTerm("");
  };

  const addBar = async (bar) => {
    setIsAdding(true); // Start adding
    try {
      const barData = {
        ...bar, // Spread operator to include all bar details
        userName: userName, // Assuming userName and userId are fetched from cookies or state
        userId: userId,
      };
      const response = await axios.post(
        `${API_ENDPOINT}/api/add-api-searched-bar`,
        barData
      );
      fetchBars();
      onCloseModal();
      toast({
        title: "Bar added successfully.",
        description: "The bar has been added to the database.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Failed to add bar.",
        description: "An error occurred while adding the bar.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <Box className="SearchAndUpload-container">
      {showManualAdd ? (
        <>
          <Box
            p={5}
            mb={5}
            boxShadow="xl"
            rounded="lg"
            bg="teal.50"
            borderColor="teal.300"
            borderWidth="1px"
            _hover={{ bg: "teal.100" }}
            transition="background-color 0.3s"
            className="maintuitio"
          >
            <Flex
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              {isMobileView ? (
                <Icon as={TbFileUnknown} color="teal.500" />
              ) : (
                <Icon
                  as={TbFileUnknown}
                  w={10}
                  h={10}
                  color="teal.500"
                  mb={3}
                />
              )}

              <Text
                fontSize={isMobileView ? "md" : "lg"}
                fontWeight="bold"
                color="teal.600"
              >
                It looks like there were no matches to your search.
              </Text>
              <Text color="teal.600">
                But, you can still add the bar manually below!
              </Text>
            </Flex>
            <Box className="theabsolut">
              <Button
                onClick={handleBack}
                size={"sm"}
                className="backtosearchbutn"
              >
                <Flex alignItems={"center"} gap={"6px"}>
                  <Icon as={BsArrowLeftCircleFill} />
                  Back to Search
                </Flex>
              </Button>
            </Box>
          </Box>

          <ManualAddBar
            onCloseModal={onCloseModal}
            isMobileView={isMobileView}
          />
        </>
      ) : (
        <>
          <Box
            p={4}
            borderRadius="lg"
            borderWidth="0px"
            borderColor="teal.600"
            color="teal.500"
            display="flex"
            alignItems="center"
          >
            <Icon as={FaCocktail} w={6} h={6} />
            <Text fontSize="lg" fontWeight="bold" ml={3}>
              Search by bar name below
            </Text>
          </Box>
          <Box className="maininputarea">
            <InputGroup>
              <Input
                pr="4.5rem"
                type="text"
                placeholder="Search by Bar Name"
                borderColor="gray.300"
                _hover={{ borderColor: "gray.400" }}
                bg="white"
                _placeholder={{ color: "gray.500" }}
                boxShadow="sm"
                _focus={{ boxShadow: "md", borderColor: "blue.500" }}
                value={searchTerm}
                onChange={handleChange}
                onKeyDown={handleKeyDown} // Add this line
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  bg={searchResults.length > 0 ? "red.100" : "orange.100"}
                  onClick={
                    searchResults.length > 0 ? handleClear : handleSearch
                  }
                >
                  {isLoading ? (
                    <Spinner size="sm" color="gray.500" />
                  ) : searchResults.length > 0 ? (
                    <Icon as={FaTimes} color="red.500" />
                  ) : (
                    <SearchIcon color="gray.500" />
                  )}
                </Button>
              </InputRightElement>
            </InputGroup>

            <Box mt={1}>
              {isLoading ? (
                <>
                  <Skeleton
                    className="resultbar"
                    p={5}
                    shadow="md"
                    borderWidth="1px"
                    bg={"mintcream"}
                  />
                </>
              ) : (
                <>
                  {searchResults.length > 0 ? (
                    <Flex className="spawnsnin">
                      {searchResults.map((bar, index) => (
                        <Flex
                          className="resultbar"
                          key={index}
                          p={3}
                          shadow="md"
                          borderWidth="1px"
                        >
                          <Flex
                            flexDirection={"column"}
                            alignItems={"start"}
                            justifyContent={"start"}
                            gap={"1px"}
                          >
                            <div>
                              <strong>{bar.name}</strong>
                            </div>
                            <div>{bar.formatted_address}</div>
                            <Tag colorScheme="blue" mt="2">
                              {getBoroughBadge(bar.formatted_address)}
                            </Tag>
                          </Flex>

                          <Button
                            onClick={() => addBar(bar)}
                            isLoading={isAdding} // Show spinner when adding
                            colorScheme="green"
                            variant={"ghost"}
                            size={"sm"}
                          >
                            <Flex alignItems={"center"} gap={"5px"}>
                              {isAdding ? (
                                <Spinner size="sm" color="gray.500" />
                              ) : (
                                <Icon as={FaPlus} />
                              )}
                              <Text className="addbartext">
                                {isAdding ? "Adding..." : "Add Bar!"}
                              </Text>
                            </Flex>
                          </Button>
                        </Flex>
                      ))}
                    </Flex>
                  ) : null}
                </>
              )}
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default SearchAndUpload;
