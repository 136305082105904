import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
  useToast,
  Heading,
  Icon,
  Text,
  HStack,
  Divider,
} from "@chakra-ui/react";
import { PhoneIcon, EmailIcon, InfoIcon } from "@chakra-ui/icons";
import Header from "../../Components/Header/Header";
import "./Contact.css";
import Footer from "../../Components/Footer/Footer";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const toast = useToast();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // You should add real submission logic here
    toast({
      title: "Message sent!",
      description: "Your message has been sent successfully!",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [subjectPlaceholder, setSubjectPlaceholder] = useState(
    "I want to add a Bar to the site"
  );
  const placeholders = [
    "I want to add a Bar to the site",
    "I am the owner of a bar on the site",
    "Can I Promote on the site?",
  ];

  useEffect(() => {
    // ... (existing useEffect contents remain unchanged)

    // Placeholder change logic
    let index = 0;
    const intervalId = setInterval(() => {
      index = index === placeholders.length - 1 ? 0 : index + 1;
      setSubjectPlaceholder(placeholders[index]);
    }, 3000); // changes every 3 seconds

    return () => {
      clearInterval(intervalId);
      // ... (existing cleanup remains unchanged)
    };
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Get In Touch | NYC Bar Finder</title>
        <meta
          name="description"
          content="Get in touch with NYC Bar Finder. Whether you want to add a bar, have inquiries about a listed bar, or explore promotions, we're here to connect."
        />
      </Helmet>
      <Header />
      <Box
        className="maincontact"
        p={isMobileView ? 8 : 5}
        bg="gray.50"
        minH="100vh"
      >
        <Box maxW="md" mx="auto">
          <Heading
            textAlign="center"
            size="xl"
            color={"teal.300"}
            fontWeight="extrabold"
          >
            Contact Us
          </Heading>
          <Text
            textAlign={"center"}
            mt={4}
            mb={8}
            align="center"
            maxW="md"
            fontWeight="medium"
            fontSize={isMobileView ? "17px" : "17px"}
          >
            <p>
              We’d love to hear from you! Send us a message using the form
              below.
            </p>
          </Text>
          <VStack spacing={6} as="form" onSubmit={handleSubmit}>
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="John Doe"
                bg="white"
              />
            </FormControl>
            <FormControl id="email">
              <FormLabel>Email</FormLabel>
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="john@example.com"
                bg="white"
              />
            </FormControl>
            <FormControl id="subject">
              <FormLabel>Subject</FormLabel>
              <Input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder={subjectPlaceholder}
                className="fade" // This applies the fading animation
                bg="white"
              />
            </FormControl>
            <FormControl id="message">
              <FormLabel>Message</FormLabel>
              <Textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Write your message..."
                bg="white"
              />
            </FormControl>
            <Button colorScheme="blue" type="submit" size="lg" fontSize="md">
              Send Message!
            </Button>
          </VStack>
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default Contact;
