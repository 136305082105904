import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Icon,
  Button,
  Image,
  Flex,
  Text,
} from "@chakra-ui/react";
import { SearchIcon, CloseIcon } from "@chakra-ui/icons";
import axios from "axios";
import "./SearchNYC.css";
import { useSearchedBarAllNyc } from "../../../../Context/SearchedBarAllNycContext";
import { useNavigate } from "react-router-dom";
import { useResetSearch } from "../../../../Context/ResetSearchContext";
import { API_ENDPOINT } from "../../../../Config/config";

const SearchNYC = () => {
  const [isFocused, setIsFocused] = useState(false);
  const [bars, setBars] = useState([]);
  const { setSearchedSelectedBarAllNyc } = useSearchedBarAllNyc();
  const { searchValueContext, setSearchValueContext, resetSearchContext } =
    useResetSearch();

  const searchComponentRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        searchComponentRef.current &&
        !searchComponentRef.current.contains(event.target)
      ) {
        setIsFocused(false); // Close the dropdown
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchComponentRef]);

  useEffect(() => {
    const fetchBars = async () => {
      try {
        const response = await axios.get(`${API_ENDPOINT}/api/getAllBars`);
        setBars(response.data.data);
      } catch (error) {
        console.error("Failed to fetch bars:", error);
      }
    };

    fetchBars();
  }, []);

  const handleClearSearch = () => {
    setSearchedSelectedBarAllNyc(null);
    resetSearchContext(); // Use the context function to reset search input
    setIsFocused(false);
    navigate("/");
  };

  const filteredBars = bars.filter((bar) =>
    bar.name.toLowerCase().startsWith(searchValueContext.toLowerCase())
  );

  const navigate = useNavigate();

  const getBoroughFromVicinity = (vicinity) => {
    if (vicinity.includes("Brooklyn")) return "Brooklyn";
    if (vicinity.includes("New York") || vicinity.includes("Manhattan"))
      return "Manhattan";
    if (vicinity.includes("Queens")) return "Queens";
    if (vicinity.includes("Bronx")) return "Bronx";
    if (vicinity.includes("Staten Island")) return "Staten Island";
    return "Unknown";
  };

  const handleSelectBar = (bar) => {
    const borough = getBoroughFromVicinity(bar.vicinity);
    setSearchedSelectedBarAllNyc(bar);
    setSearchValueContext(bar.name); // Set the search input to display the selected bar's name using context
    setIsFocused(false);
    navigate(
      `/search/${encodeURIComponent(borough)}-bars/${encodeURIComponent(
        bar.name
      )}`
    );
  };

  return (
    <Box ref={searchComponentRef} className="basesearching">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <Icon as={SearchIcon} color="gray.500" />
        </InputLeftElement>
        <Input
          type="text"
          placeholder="Search NYC Bars"
          variant="filled"
          _placeholder={{ color: "gray.500" }}
          fontSize="md"
          bg="white"
          borderColor="gray.200"
          _hover={{
            borderColor: "gray.400",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.1)",
          }}
          _focus={{
            bg: "white",
            borderColor: "blue.500",
            boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)",
          }}
          boxShadow="sm"
          borderRadius="md"
          borderWidth="1px"
          onFocus={() => setIsFocused(true)}
          value={searchValueContext} // Controlled component with value set to context's searchValue
          onChange={(e) => setSearchValueContext(e.target.value)} // Update context's searchValue on change
        />
        {searchValueContext && (
          <InputRightElement
            children={
              <Button mr={1} size="xs" onClick={handleClearSearch}>
                <CloseIcon />
              </Button>
            }
          />
        )}
        {isFocused && (
          <InputRightElement
            children={
              <Button mr={1} size="xs" onClick={handleClearSearch}>
                <CloseIcon />
              </Button>
            }
          />
        )}
      </InputGroup>
      {isFocused && filteredBars.length > 0 && (
        <Box className="searchnyc-suggestion">
          <Box className="searchboxcont">
            {filteredBars.map((bar) => (
              <Box
                key={bar._id}
                className="itemsuggestion"
                onClick={() => handleSelectBar(bar)} // Pass the bar object correctly
              >
                <Flex alignItems={"center"} gap={"6px"}>
                  <Text isTruncated className="rrusi">
                    {bar.name}
                  </Text>
                  <Text isTruncated className="scottmi">
                    {bar.vicinity}
                  </Text>
                </Flex>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SearchNYC;
