import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Center,
  SimpleGrid,
  GridItem,
  Text,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  IconButton,
  Flex,
  Tooltip,
  Skeleton,
  InputRightElement,
  InputGroup,
  Input,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { StarIcon } from "@chakra-ui/icons";
import { FaHeart, FaPlus, FaRegHeart, FaShareSquare } from "react-icons/fa";
import AffiliateLinks from "../../AffiliateLinks/AffiliateLinks";
import { AiOutlineCopy } from "react-icons/ai";
import BarModal from "../../BarModel/BarModal";
import { TbCircleLetterM, TbHeart } from "react-icons/tb";
import { BiSortAZ } from "react-icons/bi";
import { BsShareFill } from "react-icons/bs";
import "./ManhattanBarList.css";
import SocialShare from "../../Share/SocialShare";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useCookies } from "react-cookie";
import ManhattanBarUpload from "../../Upload/Manhattan/ManhattanBarUpload";
import SearchManhattanBars from "../../Search/V2/Manhattan/SearchManhattanBars";
import { useSearchedBar } from "../../../Context/SearchedBarContext";
import Login from "../../Auth/Login";
import { API_ENDPOINT } from "../../../Config/config";

const ITEMS_PER_PAGE = 12;

const ManhattanBarList = ({
  searchQuery,
  searchType,
  resetFlag,
  selectedLetter,
}) => {
  const [bars, setBars] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedBar, setSelectedBar] = useState(null);
  const [sortAsc, setSortAsc] = useState(true); // True for A to Z, False for Z to A
  const [isShareModalOpen, setShareModalOpen] = useState(false); // New state variable to handle share modal visibility
  const [shareLink, setShareLink] = useState(""); // New state variable to hold the share link
  const listRef = useRef(null);
  const [miles, setMiles] = useState(1); // initial value for miles
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const [brooklynBars, setBrooklynBars] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(ITEMS_PER_PAGE); // New state to replace currentPage
  const [isShareIconButtonModalOpen, setShareIconButtonModalOpen] =
    useState(false);
  const [cookies, setCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId; // Retrieve the user's ID from the cookie
  const toast = useToast();
  const { searchedSelectedBar } = useSearchedBar();

  const navigate = useNavigate();

  const handleShareIconButtonClick = (event, bar) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setShareIconButtonModalOpen(true);
    setSelectedBar(bar); // Set the selected bar
  };

  const fetchBars = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${API_ENDPOINT}/api/getAllManhattanBars`
      );
      const barsData = Array.isArray(response.data.data)
        ? response.data.data
        : [];
      setBars(barsData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching bars:", error);
      setIsLoading(false);
      setBars([]);
    }
  };

  useEffect(() => {
    fetchBars();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  useEffect(() => {
    if (resetFlag) {
      setBars([]);
      setIsLoading(true);
    }
  }, [resetFlag]);

  const getBoroughFromVicinity = (vicinity) => {
    if (vicinity.includes("Brooklyn")) return "Brooklyn";
    if (vicinity.includes("New York") || vicinity.includes("Manhattan"))
      return "Manhattan";
    if (vicinity.includes("Queens")) return "Queens";
    if (vicinity.includes("Bronx")) return "Bronx";
    if (vicinity.includes("Staten Island")) return "Staten Island";
    return "Unknown";
  };

  const handleBarClick = (bar) => {
    const borough = getBoroughFromVicinity(bar.vicinity);

    navigate(
      `/nyc-bars/${encodeURIComponent(borough)}-bars/${encodeURIComponent(
        bar.name
      )}`
    );
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedBar(null);
    window.history.pushState({}, "", "/bars-in/Manhattan"); // Reset URL to the default
  };

  const [isAddNewBarModalOpen, setIsAddNewBarModalOpen] = useState(false); // New state for the new modal visibility

  const [isLiked, setIsLiked] = useState(false);

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const isHalfStar = rating - fullStars >= 0.5; // Determine if there's a half star
    const halfStars = isHalfStar ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {Array(fullStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`full-${i}`} as={StarIcon} color="yellow.400" />
          ))}
        {Array(halfStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`half-${i}`} as={StarIcon} color="yellow.200" />
          ))}
        {Array(emptyStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`empty-${i}`} as={StarIcon} color="gray.300" />
          ))}
      </>
    );
  };

  const [likingBarId, setLikingBarId] = useState(null);

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const [showLoginModal, setShowLoginModal] = useState(false); // State for showing the login modal

  const handleLikeClickOrLogin = (event, barId) => {
    event.stopPropagation(); // Prevent further propagation of the click event

    if (AuthToken) {
      handleLikeClick(barId);
    } else {
      setShowLoginModal(true); // Open the login modal
    }
  };

  const filteredBars = selectedLetter
    ? bars.filter((bar) => bar.name.startsWith(selectedLetter))
    : bars;

  if (isLoading) {
    return (
      <SimpleGrid columns={[1, null, 4]} gap={4} className="listpadding">
        {Array(ITEMS_PER_PAGE)
          .fill()
          .map((_, index) => (
            <GridItem as="article" className="crill" key={index}>
              <Box
                position="relative"
                border="1px solid #ddd"
                padding="0"
                borderRadius="12px"
                w={isMobileView ? "100%" : "100%"}
                h="250px"
                boxShadow="md"
                textAlign="left"
                overflow="hidden"
              >
                <Skeleton
                  w={isMobileView ? "100%" : "100%"}
                  h="250px"
                  borderRadius="12px"
                />
              </Box>
            </GridItem>
          ))}
      </SimpleGrid>
    );
  }

  const sortedBars = [...filteredBars].sort((a, b) => {
    if (sortAsc) {
      return a.name.localeCompare(b.name); // A to Z
    } else {
      return b.name.localeCompare(a.name); // Z to A
    }
  });

  let visibleBars;
  if (searchedSelectedBar) {
    // Directly display the selected bar, ignoring sorting and pagination
    visibleBars = [searchedSelectedBar];
  } else {
    // Apply sorting and pagination if no bar is selected
    visibleBars = sortedBars.slice(0, itemsToShow);
  }
  const handleLoadMore = () => {
    setItemsToShow(itemsToShow + ITEMS_PER_PAGE);
  };

  const handleLikeClick = async (barId) => {
    setLikingBarId(barId); // Start loading for this specific bar
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/user/${userId}/likeBar`,
        { barId, userId }
      );
      if (response.data.status === "success") {
        toast({
          title: "Bar liked successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error liking the bar.",
        description: "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLikingBarId(null); // End loading
    }
  };

  return (
    <Box className="glocker">
      <Box className="btnsurround">
        <Box className="nowuesy">
          <Flex alignItems={"center"} gap={"5px"}>
            <Icon
              fontSize={isMobileView ? "34px" : "30px"}
              color={"red"}
              as={TbCircleLetterM}
            />
            <Text className="nipsye">Manhattan Bars</Text>
            {!isMobileView && (
              <>
                <Box ml={2}>
                  {searchedSelectedBar && (
                    <>
                      <Text>{searchedSelectedBar.name}</Text>
                    </>
                  )}
                </Box>
              </>
            )}
          </Flex>
          <Flex alignItems={"center"} gap={"8px"}>
            {isMobileView ? (
              <>
                <Button
                  bg={"white"}
                  variant={"outline"}
                  size={"sm"}
                  onClick={() => setSortAsc(!sortAsc)}
                >
                  <Text className="bdays">
                    {sortAsc ? "Sort Z to A" : "Sort A to Z"}
                    <Icon ml={2} as={BiSortAZ} />
                  </Text>
                </Button>
              </>
            ) : (
              <>
                <Button
                  ml={2}
                  size={"sm"}
                  bg={"white"}
                  variant={"outline"}
                  _hover={{
                    transform: "scale(1.1)", // Scale icon size when hovered
                    boxShadow: "0 4px 8px 2px rgba(0, 0, 0, 0.1)", // Box shadow when hovered
                    backgroundColor: "rgba(60, 242, 78, 0.1)",
                  }}
                  onClick={() => setSortAsc(!sortAsc)}
                >
                  {sortAsc ? "Sort Z to A" : "Sort A to Z"}
                  <Icon ml={2} as={BiSortAZ} />
                </Button>
              </>
            )}
          </Flex>
        </Box>
      </Box>

      <SimpleGrid
        mt={3}
        columns={[1, null, 4]}
        gap={isMobileView ? 0 : 7}
        className="listpadding"
      >
        {visibleBars.map((bar, index) => {
          return (
            <GridItem as="article" className="crill" key={bar.place_id}>
              <Box
                onClick={() => handleBarClick(bar)}
                key={index}
                className="allitemr"
              >
                <Flex
                  className="manhatvetsr"
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Box
                    isTruncated
                    mt={isMobileView ? 1 : 0}
                    className="manhatsosh"
                  >
                    <Box className="qlasi">
                      <Text className="fortunestock">Bar:</Text>
                    </Box>
                    <Text isTruncated className="oneperorwname">
                      {bar.name}
                    </Text>
                  </Box>
                  <Flex alignItems={"center"} gap={"15px"}>
                    {bar.Likes?.some((like) => like.userId === userId) ? (
                      <>
                        <Tooltip
                          borderRadius={"8px"}
                          mb={1}
                          label="Bar Saved"
                          zIndex={"9999"}
                          placement="top"
                        >
                          <Icon
                            _hover={{ cursor: "pointer" }}
                            as={FaHeart}
                            fontSize={"19px"}
                            color="orange"
                          />
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {likingBarId === bar._id ? (
                          <Spinner size="sm" color="red.500" />
                        ) : (
                          <Tooltip
                            borderRadius={"8px"}
                            mb={1}
                            label="Like"
                            zIndex={"9999"}
                            placement="top"
                          >
                            <Icon
                              _hover={{ cursor: "pointer" }}
                              as={FaRegHeart}
                              fontSize={"19px"}
                              // onClick={() => handleLikeClick(bar._id)}
                              // onClick={() => handleLikeClickOrLogin(bar._id)}
                              onClick={(event) =>
                                handleLikeClickOrLogin(event, bar._id)
                              }
                            />
                          </Tooltip>
                        )}
                      </>
                    )}

                    <Tooltip
                      borderRadius={"8px"}
                      mb={1}
                      label="Share"
                      placement="top"
                    >
                      <Icon
                        mr={isMobileView ? 2 : 0}
                        _hover={{ cursor: "pointer" }}
                        as={BsShareFill}
                        color={"blue"}
                        onClick={(event) =>
                          handleShareIconButtonClick(event, bar)
                        }
                      />
                    </Tooltip>
                  </Flex>
                </Flex>
                <Box className="totiajsk">
                  <Box className="allrusio">
                    <Image
                      src={bar.imageUrl}
                      alt={`Image of ${bar.name}`}
                      className="robinsimg"
                    />
                  </Box>
                </Box>
                <Box className="addressone">
                  <Box className="teahsk">
                    <Text ml={isMobileView ? 0 : 1} className="addressram">
                      Address:
                    </Text>
                    {isMobileView ? (
                      <Text className="drappeust">
                        {truncateText(bar.vicinity, 35)}
                      </Text>
                    ) : (
                      <Text className="drappeust">
                        {truncateText(bar.vicinity, 25)}
                      </Text>
                    )}
                  </Box>
                  <Box className="picketf">
                    <Tooltip
                      borderRadius={"8px"}
                      mb={1}
                      label="Copy Address"
                      placement="top"
                    >
                      <IconButton
                        size={"sm"}
                        aria-label="Copy address"
                        icon={<AiOutlineCopy />}
                        // onClick={() => handleCopy(bar.formatted_address)}
                      />
                    </Tooltip>
                  </Box>
                </Box>
                <Box mb={isMobileView ? 1 : 0} className="hepenbe">
                  {!bar.vicinity ? null : (
                    <AffiliateLinks bar={bar} searchType={searchType} />
                  )}
                </Box>
              </Box>
            </GridItem>
          );
        })}
      </SimpleGrid>
      {!searchedSelectedBar && (
        <Center>
          {itemsToShow < sortedBars.length && (
            <Button
              mt={isMobileView ? 4 : 2}
              mb={isMobileView ? 4 : 0}
              colorScheme="teal"
              variant={"outline"}
              onClick={handleLoadMore}
              aria-label="Load more bars"
            >
              Load More
            </Button>
          )}
        </Center>
      )}
      {selectedBar && (
        <>
          <BarModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            selectedBar={selectedBar}
            isMobileView={isMobileView}
            GOOGLE_API_KEY={GOOGLE_API_KEY}
            renderStars={renderStars}
          />
        </>
      )}

      {shareLink && (
        <Modal
          isOpen={isShareModalOpen}
          onClose={() => setShareModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent
            backgroundColor="#f9fafb"
            borderRadius="8px"
            border="2px solid #CBD5E0" // Elegant Border
            boxShadow="lg"
            p={1}
            color="#2d3748"
            aria-label="Share Link Modal"
          >
            <ModalHeader
              fontSize="2xl"
              fontWeight="bold"
              borderBottom="2px solid #e2e8f0"
              pb={2}
              mb={3}
              color="#4a5568"
            >
              Share {selectedBar?.name}
            </ModalHeader>
            <ModalCloseButton size="lg" aria-label="Close Share Modal" />
            <ModalBody>
              <InputGroup>
                <Input value={shareLink} isReadOnly aria-label="Share Link" />
                <InputRightElement width="2.5rem">
                  <Tooltip
                    borderRadius={"7px"}
                    label="Copy Link to Clipboard"
                    placement="top"
                  >
                    <IconButton
                      size={"sm"}
                      icon={<AiOutlineCopy />}
                      onClick={() => {
                        navigator.clipboard
                          .writeText(shareLink)
                          .then(() => {
                            console.log("copied!");
                          })
                          .catch((err) =>
                            console.error("Could not copy text: ", err)
                          );
                      }}
                      variant={"outline"}
                      colorScheme="orange"
                      aria-label="Copy Link to Clipboard"
                    />
                  </Tooltip>
                </InputRightElement>
              </InputGroup>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
      <Modal
        isOpen={isShareIconButtonModalOpen}
        onClose={() => setShareIconButtonModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Share {selectedBar?.name} </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedBar && <SocialShare barName={selectedBar.name} />}
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isAddNewBarModalOpen}
        onClose={() => setIsAddNewBarModalOpen(false)}
      >
        <ModalOverlay />
        <ModalContent
          boxShadow="xl" // Example of adding box shadow
          border="2px solid" // Example of adding border
          borderColor="gray.200" // Specifying border color
          backgroundColor="gray.50" // Background color of the modal
        >
          <ModalHeader>Add a New Bar</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ManhattanBarUpload />
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please Login Below!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Login onClose={() => setShowLoginModal(false)} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ManhattanBarList;
