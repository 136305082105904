import { Box, Flex, Link, Text } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import "./Footer.css";

const Footer = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Flex
      as="footer"
      position="fixed"
      bottom="0"
      left="0"
      right="0"
      h={isMobileView ? "12%" : "6%"}
      bg="blue.700"
      color="white"
      zIndex="1000"
    >
      <Flex
        w="100%"
        align={"center"}
        justifyContent="space-between"
        px={isMobileView ? "10px" : "20px"}
      >
        <Box className="blvdbotm">
          {isMobileView && (
            <Link className="linktext" href="/contact">
              Contact
            </Link>
          )}
          <Text className="copyfont">© 2023 NYC Barfinder</Text>
        </Box>

        <Box className="btmnavigation" as="nav" role="navigation">
          <Link className="linktext" href="/privacypolicy">
            Privacy Policy
          </Link>
          <Link className="linktext" href="/termsofservice">
            Terms of Service
          </Link>
          {!isMobileView && (
            <Link className="linktext" href="/contact">
              Contact
            </Link>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Footer;
