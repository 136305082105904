import React, { useState, useEffect } from "react";
import "./SingleBar.css";
import { Box } from "@chakra-ui/react";
import SingleBarItem from "../../Components/BarItem/SingleBarItem/SingleBarItem";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { API_ENDPOINT } from "../../Config/config";

const SingleBar = () => {
  const { barName } = useParams();
  const [barData, setBarData] = useState(null);

  useEffect(() => {
    const fetchBarData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}/api/getBarByName/${encodeURIComponent(barName)}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        const data = await response.json();
        setBarData(data.data);
      } catch (error) {
        console.error("Error fetching bar:", error);
      }
    };

    fetchBarData();
  }, [barName]);

  const structuredData = barData
    ? {
        "@context": "https://schema.org",
        "@type": "BarOrPub",
        name: barData.name,
        address: {
          "@type": "PostalAddress",
          streetAddress: barData.vicinity,
          addressRegion: "NY",
          addressCountry: "US",
        },
        geo: {
          "@type": "GeoCoordinates",
          latitude: barData.geometry.location.lat,
          longitude: barData.geometry.location.lng,
        },
        image: barData.imageUrl,
        telephone: undefined, // Add if available
        ratingValue: barData.rating,
        reviewCount: barData.user_ratings_total,
      }
    : null;

  return (
    <>
      <Helmet>
        <title>{`${barName} | NYC Bars`}</title>
        <meta
          name="description"
          content={`Explore details for ${barName}, including the bar's address, visitor ratings, and direct links to popular review sites.`}
        />
        {barData && (
          <script type="application/ld+json">
            {JSON.stringify(structuredData)}
          </script>
        )}
      </Helmet>
      <Box className="containermain">
        <SingleBarItem barName={barName} barData={barData} />
      </Box>
    </>
  );
};

export default SingleBar;
