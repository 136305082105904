// ManhattanBarContext.js
import axios from "axios";
import React, { createContext, useState, useContext } from "react";
import { API_ENDPOINT } from "../Config/config";

const ManhattanBarContext = createContext();

export const useManhattanBars = () => useContext(ManhattanBarContext);

export const ManhattanBarProvider = ({ children }) => {
  const [manhattanBars, setManhattanBars] = useState([]);

  const fetchManhattanBars = async () => {
    try {
      const response = await axios.get(`${API_ENDPOINT}/bars-in-manhattan`);
      setManhattanBars(response.data);
    } catch (error) {
      console.error("Error fetching bars in Manhattan:", error);
    }
  };

  return (
    <ManhattanBarContext.Provider
      value={{ manhattanBars, setManhattanBars, fetchManhattanBars }}
    >
      {children}
    </ManhattanBarContext.Provider>
  );
};
