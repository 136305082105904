import React, { useState } from "react";
import "./CreateAccount.css";
import {
  Box,
  Input,
  Checkbox,
  Button,
  VStack,
  Text,
  Flex,
  Icon,
  FormControl,
  FormLabel,
  FormErrorMessage,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { BsArrowUpRightCircleFill } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import { useCookies } from "react-cookie";
import { API_ENDPOINT } from "../../Config/config";

const CreateAccount = ({ closeModal, isMobileView }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
    recaptchaToken: "",
    termsAccepted: false,
  });

  const [cookies, setCookie] = useCookies();

  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false); // State to handle loading

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, termsAccepted: e.target.checked });
  };

  const handleRecaptcha = (token) => {
    setFormData({ ...formData, recaptchaToken: token });
  };

  const handleSubmit = async () => {
    if (formData.password !== formData.confirmPassword) {
      toast({
        title: "Password Error",
        description: "Passwords do not match.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    if (!formData.termsAccepted) {
      toast({
        title: "Terms Error",
        description: "You must accept the terms and conditions.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast({
        title: "Email Error",
        description: "Please enter a valid email address.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const { confirmPassword, termsAccepted, ...submitData } = formData;

    setIsLoading(true); // Start loading before the fetch call
    try {
      const response = await fetch(`${API_ENDPOINT}/api/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submitData), // Send submitData which excludes unnecessary fields
      });

      if (response.ok) {
        const data = await response.json();
        toast({
          title: "Account Created",
          description: data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setCookie("AuthToken", data.token);
        setCookie("UserId", data.userId);
        setCookie("Email", data.email);
        setCookie("Name", data.name);
        closeModal();
      } else {
        const errorData = await response.json();
        toast({
          title: "Error",
          description: errorData.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Network Error",
        description: "Unable to create account. Please try again later.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false); // Stop loading after the fetch call or if there's an error
  };

  return (
    <Box className="CreateAccount" boxShadow="md" rounded="md" bg="white">
      <VStack p={isMobileView ? 3 : 0} spacing={2}>
        <FormControl isRequired>
          <FormLabel className="inspir">Name </FormLabel>
          <Input
            name="name" // This should match the key in your formData state
            placeholder="Name"
            value={formData.name}
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel className="inspir">Email </FormLabel>
          <Input
            name="email" // This should match the key in your formData state
            placeholder="Email"
            type="email"
            borderColor="gray.300"
            value={formData.email}
            _hover={{ borderColor: "gray.400" }}
            onChange={handleChange}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel className="inspir">Password </FormLabel>
          <Input
            name="password"
            placeholder="Password"
            type="password"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            onChange={handleChange}
            value={formData.password}
          />
        </FormControl>

        <FormControl isRequired>
          <FormLabel className="inspir">Confirm Password </FormLabel>
          <Input
            name="confirmPassword" // This should match the key in your formData state
            placeholder="Confirm Password"
            type="password"
            borderColor="gray.300"
            _hover={{ borderColor: "gray.400" }}
            value={formData.confirmPassword}
            onChange={handleChange}
          />
        </FormControl>

        <VStack gap={"20px"} justifyContent={"start"} alignItems={"start"}>
          <Checkbox
            onChange={handleCheckboxChange}
            colorScheme="blue"
            isRequired
          >
            <Text className="letsisa">
              You agree to our terms of service and community guidelines{" "}
            </Text>
          </Checkbox>

          <Flex alignItems={"center"} justifyContent={"center"} w={"100%"}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={handleRecaptcha}
            />
          </Flex>
          <Flex
            w="100%"
            alignItems={"center"}
            justifyContent={"center"}
            gap={"10px"}
          >
            <Button
              isLoading={isLoading}
              loadingText="Registering..."
              onClick={handleSubmit}
              w="100%"
              colorScheme="green"
            >
              <Flex gap={"8px"} alignItems={"center"}>
                <Text>Join!</Text>
                {!isLoading && <Icon as={BsArrowUpRightCircleFill} />}
              </Flex>
            </Button>
          </Flex>
        </VStack>
      </VStack>
    </Box>
  );
};

export default CreateAccount;
