import { Box, Text } from "@chakra-ui/react";
import React from "react";

const AreaList = ({ areas, activeTab, closeMenu }) => {
  return (
    <Box>
      {areas.map((area, index) => (
        <Box key={index} hidden={activeTab !== index + 1} onClick={closeMenu}>
          {area.content}
        </Box>
      ))}
    </Box>
  );
};

export default AreaList;
