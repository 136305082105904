import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Center,
  SimpleGrid,
  GridItem,
  Text,
  Button,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  IconButton,
  Flex,
  Tooltip,
  Skeleton,
  InputRightElement,
  InputGroup,
  Input,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import { useCookies } from "react-cookie";
import { FaHeart, FaPlus, FaRegHeart, FaShareSquare } from "react-icons/fa";
import { API_ENDPOINT } from "../../../Config/config";
import axios from "axios";
import { BsShareFill } from "react-icons/bs";
import { AiOutlineCopy } from "react-icons/ai";
import BarModal from "../../BarModel/BarModal";
import { StarIcon } from "@chakra-ui/icons";
import AffiliateLinks from "../../AffiliateLinks/AffiliateLinks";
import Login from "../../Auth/Login";

const SingleBarItem = ({ barName, barData }) => {
  const [showLoginModal, setShowLoginModal] = useState(false); // State for showing the login modal
  const [isModalOpen, setModalOpen] = useState(false);
  const [cookies, setCookie] = useCookies([
    "AuthToken",
    "UserId",
    "Name",
    "Email",
  ]);
  const AuthToken = cookies.AuthToken;
  const userId = cookies.UserId;
  const toast = useToast();
  const [isShareIconButtonModalOpen, setShareIconButtonModalOpen] =
    useState(false);
  const [selectedBar, setSelectedBar] = useState(null);

  const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);
  const [likingBarId, setLikingBarId] = useState(null);

  if (!barData) {
    return <Box>Loading...</Box>;
  }

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const isHalfStar = rating - fullStars >= 0.5; // Determine if there's a half star
    const halfStars = isHalfStar ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {Array(fullStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`full-${i}`} as={StarIcon} color="yellow.400" />
          ))}
        {Array(halfStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`half-${i}`} as={StarIcon} color="yellow.200" />
          ))}
        {Array(emptyStars)
          .fill(0)
          .map((_, i) => (
            <Icon key={`empty-${i}`} as={StarIcon} color="gray.300" />
          ))}
      </>
    );
  };

  const handleShareIconButtonClick = (event, bar) => {
    event.stopPropagation(); // Prevent event from bubbling up
    setShareIconButtonModalOpen(true);
  };

  const handleBarClick = (barData) => {
    setModalOpen(true);
    setSelectedBar(barData);
  };

  const handleLikeClickOrLogin = (event, barId) => {
    event.stopPropagation(); // Prevent further propagation of the click event

    if (AuthToken) {
      handleLikeClick(barId);
    } else {
      setShowLoginModal(true); // Open the login modal
    }
  };

  const handleLikeClick = async (barId) => {
    setLikingBarId(barId); // Start loading for this specific bar
    try {
      const response = await axios.post(
        `${API_ENDPOINT}/api/user/${userId}/likeBar`,
        { barId, userId }
      );
      if (response.data.status === "success") {
        toast({
          title: "Bar liked successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Error liking the bar.",
        description: "An unexpected error occurred.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLikingBarId(null); // End loading
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };
  return (
    <Box>
      <Box className="barsearcheditemcon">
        <Box onClick={() => handleBarClick(barData)} className="framingitem">
          <Flex
            className="manhatvetsr"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box isTruncated mt={isMobileView ? 1 : 0} className="manhatsosh">
              <Box className="qlasi">
                <Text className="fortunestock">Bar:</Text>
              </Box>
              <Text isTruncated className="oneperorwname">
                {truncateText(barData.name, 20)}
              </Text>
            </Box>
            <Flex alignItems={"center"} gap={"15px"}>
              {barData.Likes?.some((like) => like.userId === userId) ? (
                <>
                  <Tooltip
                    borderRadius={"8px"}
                    mb={1}
                    label="Bar Saved"
                    zIndex={"9999"}
                    placement="top"
                  >
                    <Icon
                      _hover={{ cursor: "pointer" }}
                      as={FaHeart}
                      fontSize={"19px"}
                      color="orange"
                    />
                  </Tooltip>
                </>
              ) : (
                <>
                  {likingBarId === barData._id ? (
                    <Spinner size="sm" color="red.500" />
                  ) : (
                    <Tooltip
                      borderRadius={"8px"}
                      mb={1}
                      label="Like"
                      zIndex={"9999"}
                      placement="top"
                    >
                      <Icon
                        _hover={{ cursor: "pointer" }}
                        as={FaRegHeart}
                        fontSize={"19px"}
                        onClick={(event) =>
                          handleLikeClickOrLogin(event, barData._id)
                        }
                      />
                    </Tooltip>
                  )}
                </>
              )}

              <Tooltip
                borderRadius={"8px"}
                mb={1}
                label="Share"
                placement="top"
              >
                <Icon
                  mr={isMobileView ? 2 : 0}
                  _hover={{ cursor: "pointer" }}
                  as={BsShareFill}
                  color={"blue"}
                  onClick={(event) =>
                    handleShareIconButtonClick(event, barData)
                  }
                />
              </Tooltip>
            </Flex>
          </Flex>
          <Box className="indieitemcontainer">
            <Image
              src={barData.imageUrl}
              alt={`Image of ${barData.name}`}
              className="indieitem"
            />
          </Box>
          <Box isTruncated className="addressone">
            <Box isTruncated className="teahsk">
              <Text ml={isMobileView ? 0 : 1} className="addressram">
                Address:
              </Text>
              {isMobileView ? (
                <Text className="drappeust">
                  {truncateText(barData.vicinity, 25)}
                </Text>
              ) : (
                <Text isTruncated className="drappeust">
                  {truncateText(barData.vicinity, 25)}
                </Text>
              )}
            </Box>
            <Box className="picketf">
              <Tooltip
                borderRadius={"8px"}
                mb={1}
                label="Copy Address"
                placement="top"
              >
                <IconButton
                  size={"sm"}
                  aria-label="Copy address"
                  icon={<AiOutlineCopy />}
                  // onClick={() => handleCopy(bar.formatted_address)}
                />
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </Box>
      {selectedBar && (
        <>
          <BarModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            selectedBar={selectedBar}
            isMobileView={isMobileView}
            GOOGLE_API_KEY={GOOGLE_API_KEY}
            renderStars={renderStars}
          />
        </>
      )}
      <Modal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Please Login Below!</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Login onClose={() => setShowLoginModal(false)} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default SingleBarItem;
