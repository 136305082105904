import React, { useEffect, useState } from "react";
import { Box, Divider, Heading, Text, VStack } from "@chakra-ui/react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./TermsConditions.css";
import { Helmet } from "react-helmet";

const TermsConditions = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <Box className="privacy" bg="gray.100" minHeight="100vh">
      <Helmet>
        <title>Terms of Service | NYC Bar Finder</title>
        <meta
          name="description"
          content="Read NYC Bar Finder's Terms of Service. Understand your rights, responsibilities, and the guidelines for using our platform. Ensure you're informed about our service's guidelines."
        />
      </Helmet>
      <Header />
      <VStack spacing={isMobileView ? 6 : 6} align="start">
        <Heading mb={3} as="h1" size="lg">
          Terms of Service
        </Heading>

        <Heading as="h2" size="md">
          1. Introduction
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          Welcome to our site! By using our service, you agree to the following
          Terms of Service. Please read them carefully.
        </Text>

        <Heading as="h2" size="md">
          2. Service Overview
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          Our service allows users to discover bars in New York City's 5
          boroughs. You can search by Borough, neighborhood, or zip code to
          discover bars throughout the New York City area, utilizing data from
          Google Places API to ensure accurate and up-to-date results.
        </Text>

        <Heading as="h2" size="md">
          3. How Our Service Works
        </Heading>
        <Text textAlign={"start"}>
          When a search is made, our service fetches data from Google Places API
          to display a list of bars in the specified Borough, neighborhood, or
          zip code of New York City. Users can explore various bars, view
          detailed information, and read reviews.
        </Text>

        <Heading as="h2" size="md">
          4. User Obligations
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          As a user of our service, you agree to: - Use the service ethically
          and responsibly. <br />- Refrain from using the service for any
          unlawful or malicious activities. <br />- Respect the intellectual
          property rights of content available on the website. <br />- Not
          introduce malicious software or attempt to breach the security of our
          website
          <br /> <Divider mt={1} p={0.2} bg={"grey"} />
          Any misuse of our service for malicious intent, or in a manner that
          violates any laws or infringes on the rights of others, is strictly
          prohibited.
        </Text>

        <Heading as="h2" size="md">
          5. Limitations and Exclusions
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          We rely on third-party platforms (like Google Places API) for data. We
          do not guarantee the accuracy or availability of any information
          fetched or displayed by our service.
          <br /> <Divider mt={1} p={0.2} bg={"grey"} />
          We are not affiliated with Google or any other third-party service
          referenced on our site, and any discrepancies or inaccuracies in the
          information provided should be addressed with the respective service
          providers.
        </Text>

        <Heading as="h2" size="md">
          6. Ads and Promotions
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          We may display ads and promotions from third-party sources, including
          Google AdSense. By using our platform, you agree to the potential
          display of such content and understand that our terms and privacy
          policies might not apply to content from third-party advertisers or
          websites.
        </Text>

        <Heading as="h2" size="md">
          9. Changes to Terms
        </Heading>
        <Text fontWeight={300} textAlign={"start"}>
          Our terms of service may be updated periodically. We recommend
          checking this page regularly for any modifications.
        </Text>

        <Heading as="h2" size="md">
          8. Contact Us
        </Heading>
        <Text mb={isMobileView ? 8 : 8} fontWeight={300} textAlign={"start"}>
          If you have any questions or concerns about our terms of service,
          please contact us at contactnycbarfinder@nycbarfinder.com.
        </Text>
      </VStack>
      <Footer />
    </Box>
  );
};

export default TermsConditions;
